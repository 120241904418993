import React from "react";
import ArchivesPhaseOne from "./ArchivesPhaseOne";

function ArchivesLandingPage() {
  document.title = "Archives - TARO";
  return (
    <div>
      <ArchivesPhaseOne />
    </div>
  );
}

export default ArchivesLandingPage;
