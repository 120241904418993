import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  document.title = "Page Not Found";

  return (
    <div className="container-fluid">
      <h1>404</h1>
      <h3>Page Not Found</h3>
      <p>The page you are looking for cannot be found</p>
      <Link to="/home">Head back to the homepage</Link>
    </div>
  );
}

export default NotFound;
