import { combineReducers } from "redux";
import findingAids from "./findingAids";
import findingAid from "./findingAid";
import facets from "./facets";
import creators from "./creators";
import repository from "./repository";
import allowedList from "./allowedList";

export default combineReducers({
  findingAids,
  findingAid,
  facets,
  repository,
  creators,
  allowedList,
});
