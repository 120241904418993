import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ResultsHeader from "./SearchResultsHeader";
import ResultsSort from "./SearchResultsSort";
import SearchQueryInfo from "./SearchQueryInfo";
import ReactPaginate from "react-paginate";
import { searchFindingAids } from "../../actions/searchFindingAids";
import Loading from "../Utils/Loading";
import { wordRestrict } from "../Utils/WordCounter";
import { scrollToTop } from "../Utils/ScrollToTop";

//TODO: write out a statement for search made
function SearchResults(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [sortedResults, setSortedResults] = useState([]);
  const [pass, setPass] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [toggleSummary, setToggleSummary] = useState(false);
  const [loading, setLoading] = useState(props.loading);
  const resultsSection = useRef(null);
  const resultsHeader = useRef(null);
  const query = window.location.pathname;

  // Can overwrite what finding aids populate here by specifying overwriteAids as a prop
  const { findingAids, repositoriesList } = props;
  // console.log("results", props);
  
  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  // console.log('update loading on results', loading)

  useEffect(() => {
    props.updateLoading(true);
    if (findingAids.length === 0 || findingAids[0] === "found nothing") {
      setPass(false);
    } else if (findingAids[0] !== "found nothing") {
      setPass(true);
    } else if (findingAids[0] === "found nothing") {
      setPass(false);
    }

    setSortedResults(findingAids);
    setCurrentPage(0);
    props.updateLoading(false);
  }, [perPage, pass, findingAids, sortBy, query]);

  const handleSortBy = (type) => {
    setSortBy(type);
  };

  const sortDates = (arr) => {
    if (arr === undefined) {
      return ["No date provided."];
    } else {
      return arr.sort((a, b) => parseInt(a) - parseInt(b));
    }
  };

  const handleNoResults = () => {
    if (findingAids[0] === "found nothing") {
      return (
        <div className="card-body">
          <h4 className="text-center">{findingAids[1]}</h4>
        </div>
      );
    } else {
      return <div className="card-body"></div>;
    }
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    props.setPage(selectedPage); 
    setCurrentPage(selectedPage);
    scrollToTop();  
  };

  const handleToggleSummary = (e) => {
    setToggleSummary(!toggleSummary);
  };

  const offset = currentPage * perPage;
  const pageCount = Math.ceil(sortedResults.length / perPage);
  const currentPageData =
    sortedResults.length > 0 ? (
      sortedResults.slice(offset, offset + perPage).map((result, i) => {
        let sortedCreators = "";
        // console.log("result creator", result);
        if (result.creators !== undefined && result.creators.length > 0) {
          if (typeof result.creators === "object") {
            sortedCreators = result.creators.sort((a, b) => a.localeCompare(b));
          } else {
            sortedCreators = [result.creators];
          }
        } else {
          sortedCreators = ["No creator listed on this finding aid"];
        }
        return (
          <div className="repository-link card" key={i}>
            <div className="card-body">
              <p className="card-title results-header">
                {sortBy === "title" ? "Title: " : null}{" "}
                <Link
                  to={`/${result.repository}/finding_aids/${result.filename}`}
                  className="card-text"
                >
                  {result.title}
                </Link>
              </p>
              <p className="card-subtitle mb-2 text-muted results-text">
                {wordRestrict(result.abstract).length > 0 ? (
                  wordRestrict(result.abstract)
                ) : (
                  <em>No abstract provided</em>
                )}
              </p>
              <p className="card-text results-text">
                {sortBy === "repository" ? "Repository: " : null}
                <Link to={`/repositories/${result.repository}`}>
                  {result.repository_name}
                </Link>{" "}
              </p>
              {sortBy === "creators" ? (
                <p>
                  Creator :{" "}
                  {result.creators !== null ? (
                    <ul>
                      {sortedCreators.map((each, i) => (
                        <li key={i}>{each}</li>
                      ))}
                    </ul>
                  ) : (
                    <em>No creator provided</em>
                  )}
                </p>
              ) : null}
              {sortBy === "start_dates" ? (
                <p>
                  Start Dates :{" "}
                  {result.start_dates !== null ? (
                    sortDates(result.start_dates).map((each, i) => (
                      <li key={i}>{each}</li>
                    ))
                  ) : (
                    <em>No dates provided</em>
                  )}
                </p>
              ) : null}
              {sortBy === "end_dates" ? (
                <p>
                  End Dates :{" "}
                  {result.end_dates !== null ||
                  result.end_dates !== undefined ? (
                    sortDates(result.end_dates)
                      .reverse()
                      .map((each, i) => <li key={i}>{each}</li>)
                  ) : (
                    <em>No dates provided</em>
                  )}
                </p>
              ) : null}
            </div>
          </div>
        );
      })
    ) : (
      <Loading results={sortedResults} from="search" />
    );

  //mobile scrolling
  useEffect(() => {
    if (resultsHeader !== null) {
      props.scrolling(resultsHeader);
    }
  }, [resultsHeader]);

  return (
    <div>
      {pass ? (
        <div>
          {" "}
          {loading ? (
            <Loading results={sortedResults} from="search" />
          ) : (
            <div className="card">
              <div>
                <div ref={resultsHeader}>
                  {props.home ? null : (
                    <ResultsHeader
                      details={findingAids}
                      total={findingAids.length}
                      display={
                        currentPageData === null ? 0 : currentPageData.length
                      }
                      pageNumber={currentPage}
                      perPage={perPage}
                      setPerPage={setPerPage}
                      sortTerms={setSortedResults}
                      loading={props.updateLoading}
                    />
                  )}
                  <SearchQueryInfo
                    repositoriesList={repositoriesList}
                    toggleSummary={handleToggleSummary}
                    toggle={toggleSummary}
                  />
                </div>
                <div ref={resultsSection}>
                  <ResultsSort
                    sortInfo={handleSortBy}
                    loading={props.updateLoading}
                  />
                </div>
              </div>
              {currentPageData}
              <div>
                {props.home ? null : (
                  <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={currentPage}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    disabledClassName={"page-item disabled"}
                    activeClassName={"page-item active"}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        handleNoResults()
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  findingAids: state.findingAids.findingAids,
});

export default connect(mapStateToProps, { searchFindingAids })(SearchResults);
