import React from "react";
import { connect } from "react-redux";
import { searchFindingAids } from "../../actions/searchFindingAids";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

function OtherResources(props) {
  return (
    <AccordionItem uuid="other-resources" className="summary card">
      <AccordionItemHeading className="card-header">
        <AccordionItemButton>
          <a name={props.name}>
            <p className="card-title accordion-headers" id="other_resources">
              Other Resources
            </p>
          </a>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="card-body">
        <div className="card-text">
          {Object.keys(props.list).map((item, i) => (
            <div key={i}>
              {item.includes("</strong>") ? (
                <p
                  tabIndex="0"
                  dangerouslySetInnerHTML={{
                    __html: item,
                  }}
                ></p>
              ) : (
                <p
                  className="label"
                  tabIndex="0"
                  dangerouslySetInnerHTML={{
                    __html: item,
                  }}
                ></p>
              )}
              <p
                className="text "
                tabIndex="0"
                dangerouslySetInnerHTML={{
                  __html: " " + props.list[item],
                }}
              ></p>
            </div>
          ))}
        </div>
      </AccordionItemPanel>
    </AccordionItem>
  );
}

const mapStateToProps = (state) => ({
  findingAid: state.findingAids.findingAids,
});

export default connect(mapStateToProps, { searchFindingAids })(OtherResources);
