import axios from "axios";
import { SEARCH_CREATORS } from "./types";
import { tokenConfig } from "./auth";

// SEARCH CREATORS
// This is used to populate our browse-by creators page.
export const searchCreators = (query) => (dispatch, getState) => {
  axios
    .get(
      `${process.env.REACT_APP_API_BASE}/api/creators/search/?${query}`,
      tokenConfig(getState)
    )
    .then((response) => {
      if (response.status === 200 && response.data.length === 0) {
        window.location.href = "/system_down"; 
      } else {
      dispatch({
        type: SEARCH_CREATORS,
        payload: response.data,
      });
      }
    })
    .catch((err) => {
      if (err === 500) {
        window.location.href = "/system_down";
      } else {
        console.log(err)
      }
    });
};
