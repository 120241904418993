import React from "react";
import XmlLink from "./XmlLink";
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import JsonLink from "./JsonLink";

export default function OtherFormats(props) {
  const toggled = props.toggled;

  //need to have a link so the information can be pulled out as xml, maybe use anchor tags instead if they formats are not locally hosted?
  return (
    <div>
      <div className="card">
        <div className="sticky-top sidebar-toggle-wrapper d-lg-none ml-auto">
          <button
            className={`btn sidebar-toggle dark-bg sidebar-toggle-sidebar toggled ${
              toggled ? "show" : "hidden"
            }`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebar"
            aria-expanded="false"
            aria-controls="collapseSidebar"
            onClick={() => {
              props.setToggled(!toggled);
            }}
          />
        </div>
        <AccordionItemHeading className="card-header">
          <AccordionItemButton>
            <div className="card-title lead">View Other Versions</div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="card-body">
          <XmlLink />
          {props.printLink}
          <JsonLink />
        </AccordionItemPanel>
      </div>
    </div>
  );
}
