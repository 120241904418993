import React, { useRef } from "react";
import Logo from "../../images/logo.png";
import useImgResize from "../../Hook/useImgResize";

//do you want to add resize hook here as well?
function LogoUTL() {
  const parentRef = useRef(null);
  const imgRef = useRef(null);
  // const styling = useImgResize(parentRef, imgRef);

  return (
    <div>
      <div id="utl-logo" ref={parentRef} style={{ width: "100%" }}>
        <a href={`https://www.lib.utexas.edu/`}>
          <img
            src={Logo}
            ref={imgRef}
            style={{ width: "95%" }}
            alt="University of Texas at Austin Libraries logo"
          />
        </a>
      </div>
    </div>
  );
}
export default LogoUTL;
