import { useEffect } from "react";
import { connect } from "react-redux";
import { searchFindingAid } from "../../actions/searchFindingAid";
import { withRouter } from "react-router-dom";

// Initial component for finding aid display - makes API call and sets the result to state through Redux.
function FindingAidJSON(props) {
  useEffect(() => {
    const query = `repository=${props.match.params.repository}&filename__exact=${props.match.params.findingAid}`;
    props.searchFindingAid(query);
  }, [props]);
  return null;
}

export default withRouter(connect(null, { searchFindingAid })(FindingAidJSON));
