import React, { Component } from "react";
import FindingAidPrintContent from "./FindingAidPrintContent";

class ComponentToPrint extends Component {
  // note: this is a class-based wrapper component
  // we are using this to wrap our functional component
  // because react-to-print only works with class-based components
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <FindingAidPrintContent
        toggled={this.props.toggled}
        setToggled={this.props.setToggled}
      />
    );
  }
}
export default ComponentToPrint;
