import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { searchRepositories } from "../../actions/searchRepositories";
import { Link } from "react-router-dom";
import RepositoriesHeader from "./RepositoriesHeader";

function RepositoryList(props) {
  const [repositories, setRepositories] = useState([]);

  useEffect(() => {
    if (props.repositoryData.length > 0) {
      let copy = [...props.repositoryData];
      setRepositories(copy);
    }
  }, [props.repositoryData]);

  const currentPageData = repositories.map((repository, i) => (
    <div className="repository-link card" key={i}>
      <div className="card-body">
        <p className="card-title results-header">
          <Link to={`/repositories/${repository.abbr_name}`}>
            {repository.name}
          </Link>
        </p>
        <p
          className="card-subtitle mb-2 text-muted results-text"
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: repository.description,
          }}
        ></p>
      </div>
    </div>
  ));

  const total = repositories.length;

  return (
    <div>
      <RepositoriesHeader props={props} total={total} />
      {currentPageData}
      <br />
    </div>
  );
}

const mapStateToProps = (state) => ({
  repositoryData: state.repository.repositoryData,
});

export default connect(mapStateToProps, { searchRepositories })(RepositoryList);
