import React from "react";
import { Link } from "react-router-dom";
import Loading from "../Utils/Loading";
import { wordRestrict } from "../Utils/WordCounter";

function RepoCollection(props) {
  function getRandom(arr, n) {
    // function to randomly select a given number of values from an array
    // if number exceeds length of array, just returns the array
    // this is used to display a random selection of up to 10 finding aids
    // associated with the repository
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len) return arr;
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

  const numberToDisplay = 10;
  const findingAidsToDisplay = getRandom(props.findingAids, numberToDisplay);

  const validateResults = () => {
    if (findingAidsToDisplay[0] === "found nothing") {
      return (
        <div className="finding-aid">
          <h4 className="text-center">
            Sorry, this repository doesn't have any finding aids at this time.
          </h4>
          <br />
        </div>
      );
    } else {
      return currentPageData;
    }
  };

  const currentPageData = findingAidsToDisplay.map((result, i) => (
    <div className="repository-link card" key={i}>
      <div className="card-body">
        <div className="finding-aid card" key={i}>
          <div className="card-body">
            <p className="card-title results-header">
              Title:{" "}
              <Link
                to={`/${result.repository}/finding_aids/${result.filename}`}
                className="card-text"
              >
                {result.title}
              </Link>
            </p>
            <p className="card-subtitle mb-2 text-muted results-text">
              Abstract: {wordRestrict(result.abstract)}
            </p>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="container repo-collection">
      <h3 className="text-center">Sample Finding Aids</h3>
      <p className="text-center">
        A random selection of up to 10 finding aids from this repository.
      </p>
      {findingAidsToDisplay.length > 0 ? (
        validateResults()
      ) : (
        <Loading results={findingAidsToDisplay} from="repository" />
      )}
    </div>
  );
}

export default RepoCollection;
