import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { searchRepositories } from "../../actions/searchRepositories";
import { searchFindingAids } from "../../actions/searchFindingAids";
import RepoJumbo from "./RepoJumbo";
import RepoCollection from "./RepoCollection";
import GetFindingAids from "./getFindingAids";

function RepositoryDisplay(props) {
  const [currentRepo, setCurrentRepo] = useState([]);
  const [title, setTitle] = useState([]);
  const [logo, setLogo] = useState([]);
  const [description, setDescription] = useState([]);
  const [address, setAddress] = useState([]);
  const [links, setLinks] = useState([]);
  const [findingAids, setFindingAids] = useState([]);

  let results =
    Object.keys(props.repositoryData).length > 0 ? props.repositoryData : null;

  let findingAidResults =
    Object.keys(props.findingAids).length > 0 ? props.findingAids : null;

  useEffect(() => {
    if (Object.keys(props.findingAids).length > 0) {
      setFindingAids(findingAidResults);
    }
    if (Object.keys(props.repositoryData).length > 0) {
      for (const repo of results) {
        if (props.repo === repo.abbr_name) {
          setCurrentRepo(repo);
        }
      }
      const title = currentRepo.name;
      const description = currentRepo.description;
      const address = currentRepo.address;
      const links = {
        about: currentRepo.about_link,
        contact: currentRepo.external_email,
        home: currentRepo.home_link,
        access: currentRepo.access_link,
      };
      document.title = `${title} - TARO`;
      setTitle(title);
      setLogo(`${process.env["REACT_APP_API_BASE"]}/admin/${currentRepo.logo}`); // note, this will work
      // when deployed to Rancher but not locally. note sure why finding
      // aid version does work since it's same value
      setDescription(description);
      setLinks(links);
      setAddress(address);
    }
  }, [
    results,
    currentRepo,
    findingAids,
    props.repositoryData,
    props.findingAids,
  ]);

  return (
    <div>
      <GetFindingAids />
      <RepoJumbo
        repoTitle={title}
        repoLogo={logo}
        repoDescription={description}
        repoAddress={address}
        links={links}
        repoAbbr={props.repo}
      />
      <div className="repo-collection">
        <RepoCollection findingAids={findingAids} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  repositoryData: state.repository.repositoryData,
  findingAids: state.findingAids.findingAids,
});

export default connect(mapStateToProps, {
  searchRepositories,
  searchFindingAids,
})(RepositoryDisplay);
