import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { searchFindingAids } from "../../../actions/searchFindingAids";
import { withRouter } from "react-router-dom";
import { useInputText } from "../../../Hook/useInputText";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

function Form(props) {
  const { repositoriesList } = props;
  const [repository, setRepository] = useState([""]);
  const [results, setResults] = useState(repositoriesList);
  const [defaultRepo, setDefaultRepo] = useState("");
  const [digital, setDigital] = useState(false);
  const [error, setError] = useState(false);
  const query = window.location.pathname;
  const advanceRepoFieldRef = useRef(null);

  const digitalToolTip =
    "Search finding aids with links to digitized collection material.";

  const errorMsg = [
    { odd: `* Please close your " before you can submit your search` },
    { location: ` * Quotations must include the complete text in this field` },
  ];

  let {
    value: generalField,
    bind: bindGeneralField,
    valid: validateGeneralField,
    errorReason: generalFieldError,
    reset: resetGeneralField,
  } = useInputText("");

  let {
    value: creator,
    bind: bindCreator,
    valid: validateCreator,
    errorReason: creatorError,
    reset: resetCreator,
  } = useInputText("");

  let {
    value: geographicArea,
    bind: bindGeographicArea,
    valid: validateGeographicArea,
    errorReason: geoAreaError,
    reset: resetGeographicArea,
  } = useInputText("");

  let {
    value: subject,
    bind: bindSubject,
    valid: validateSubject,
    errorReason: subjectError,
    reset: resetSubject,
  } = useInputText("");

  let {
    value: subjectPerson,
    bind: bindSubjectPerson,
    valid: validateSubjectPerson,
    errorReason: subjectErrorPerson,
    reset: resetSubjectPerson,
  } = useInputText("");

  let {
    value: subjectOrganization,
    bind: bindSubjectOrganization,
    valid: validateSubjectOrganization,
    errorReason: subjectErrorOrganization,
    reset: resetSubjectOrganization,
  } = useInputText("");

  let {
    value: title,
    bind: bindTitle,
    valid: validateTitle,
    errorReason: titleError,
    reset: resetTitle,
  } = useInputText("");

  useEffect(() => {
    if (
      !validateGeneralField ||
      !validateCreator ||
      !validateGeographicArea ||
      !validateSubject ||
      !validateSubjectPerson ||
      !validateSubjectOrganization ||
      !validateTitle
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [
    validateGeneralField,
    validateCreator,
    validateGeographicArea,
    validateSubject,
    validateSubjectPerson,
    validateSubjectOrganization,
    validateTitle,
  ]);

  useEffect(() => {
    let newResults = {};
    if (Object.keys(repositoriesList).length > 0) {
      repositoriesList.forEach((entry) => {
        newResults[entry.name.trim()] = entry.abbr_name;
      });
    }
    setResults(newResults);
  }, [repositoriesList]);

  let CurrentQuery = {
    creators: "",
    geographic_areas: "",
    text: "",
    repository: "",
    subject_topics: "",
    subject_persons: "",
    subject_organizations: "",
    title: "",
    digital: digital,
  };

  //Set the values of text fields based on the current url search input
  useEffect(() => {
    //reset everything before you start this process :
    setError(false);
    setRepository([""]);
    resetGeneralField();
    resetCreator();
    resetGeographicArea();
    resetSubject();
    resetSubjectPerson();
    resetSubjectOrganization();
    resetTitle();
    setDefaultRepo("");
    setDigital(false);

    //look at the current url to pull information
    let deconstruct = query.split("/");
    // console.log("deconstruct : ", deconstruct);
    if (deconstruct[2] != ":query") {
      for (let i = 2; i < deconstruct.length; i++) {
        let pair = deconstruct[i].split(/[=&]/);
        Object.keys(CurrentQuery).forEach((key, i) => {
          if (pair.indexOf(key) > -1) {
            let value = pair.indexOf(key) + 1;
            if (key === "repository") {
              CurrentQuery["repository"] = pair[value];
              repositoriesList.forEach((repo) => {
                if (repo.abbr_name.trim() === CurrentQuery["repository"]) {
                  return (CurrentQuery["repository"] = repo.name);
                }
              });
            } else {
              let newText = pair[value];
              if (newText.includes("%")) {
                newText = decodeURIComponent(newText);
              }
              return (CurrentQuery[key] = newText);
            }
          }
        });
      }

      Object.keys(CurrentQuery).forEach((key) => {
        if (CurrentQuery[key].length > 0) {
          switch (key) {
            case "creators":
              bindCreator.value = CurrentQuery[key];
              bindCreator.onChange(bindCreator.value);
              break;
            case "geographic_areas":
              bindGeographicArea.value = CurrentQuery[key];
              bindGeographicArea.onChange(bindGeographicArea.value);
              break;
            case "text":
              bindGeneralField.value = CurrentQuery[key];
              bindGeneralField.onChange(bindGeneralField.value);
              break;
            case "subject_topics":
              bindSubject.value = CurrentQuery[key];
              bindSubject.onChange(bindSubject.value);
              break;
            case "subject_persons":
              bindSubjectPerson.value = CurrentQuery[key];
              bindSubjectPerson.onChange(bindSubjectPerson.value);
              break;
            case "subject_organizations":
              bindSubjectOrganization.value = CurrentQuery[key];
              bindSubjectOrganization.onChange(bindSubjectOrganization.value);
              break;
            case "title":
              bindTitle.value = CurrentQuery[key];
              bindTitle.onChange(bindTitle.value);
              break;
            case "repository":
              // console.log(CurrentQuery["repository"]);
              setDefaultRepo(CurrentQuery["repository"]);
              break;
            case "digital":
              if (CurrentQuery["digital"] !== digital) {
                setDigital(CurrentQuery[key]);
              }
          }
        }
      });
    }
  }, [query]);

  //FORM FUNCTIONS
  const handleResetSearch = (e) => {
    e.preventDefault();
    setError(false);
    setRepository([""]);
    resetGeneralField();
    resetCreator();
    resetGeographicArea();
    resetSubject();
    resetSubjectPerson();
    resetSubjectOrganization();
    resetTitle();
    setDefaultRepo("");
    setDigital(false);
    props.history.push(`/search/:query`);
  };

  //QUERY FUNCTION

  const findErrorMsg = (field) => {
    let msg = "";
    errorMsg.forEach((entry) =>
      Object.keys(entry).find((key) => {
        if (key === field) {
          msg = entry[key];
        }
      })
    );
    return msg;
  };

  const handleSubmitQuery = (e) => {
    e.preventDefault();
    let abbr = "";

    if (!error) {
      let QueryDetails = {
        creators: /\S/.test(creator) ? creator.trim() : "",
        geographic_areas: /\S/.test(geographicArea)
          ? geographicArea.trim()
          : "",
        text: /\S/.test(generalField) ? generalField.trim() : "",
        repository: /\S/.test(repository) ? repository : "",
        subject_topics: /\S/.test(subject) ? subject.trim() : "",
        subject_persons: /\S/.test(subjectPerson) ? subjectPerson.trim() : "",
        subject_organizations: /\S/.test(subjectOrganization)
          ? subjectOrganization.trim()
          : "",
        title: /\S/.test(title) ? title.trim() : "",
        digital: digital ? true : "",
      };

      // console.log(QueryDetails);

      if (!error) {
        const findAbbr = (current) => {
          repositoriesList.forEach((repo) => {
            if (current === repo.name.trim()) {
              return (abbr = repo.abbr_name.trim());
            }
          });
        };

        if (defaultRepo !== "" && repository[0] === "") {
          findAbbr(defaultRepo);
          QueryDetails.repository = abbr;
        } else if (repository[0] !== "") {
          findAbbr(repository[0]);
          QueryDetails.repository = abbr;
        } else {
          QueryDetails.repository = repository[0];
        }

        let queryString = "";
        for (var index in QueryDetails) {
          if (queryString.length > 0 && QueryDetails[index] !== "") {
            queryString += "&";
          }
          if (QueryDetails[index] !== "") {
            queryString += index + "=" + QueryDetails[index];
          }
        }

        if (queryString === "") {
          props.history.push("/search/:query");
        } else {
          props.searchFindingAids(queryString);
          props.history.push(`/search/${queryString}`);
        }
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmitQuery}
      onKeyDown={(e) => (e.key === "Enter" ? handleSubmitQuery(e) : null)}
      className="form-group col-12 search-form"
    >
      <div className="row g-3 align-items-center">
        <div className="col-md-4 form-drop-dropdown">
          <label aria-label="repository" className="col-form-label">
            Repository
          </label>
          {Object.keys(results).length > 0 && (
            <Typeahead
              ref={advanceRepoFieldRef}
              placeholder={defaultRepo !== "" ? defaultRepo : ""}
              id="basic-typeahead-single repository"
              labelkey="name"
              label="limit by repository"
              onChange={setRepository}
              options={Object.keys(results)}
              selected={repository}
              value={repository}
              onKeyDown={(e) =>
                e.key === "Backspace" || e.key === "Delete"
                  ? setDefaultRepo("")
                  : null
              }
              aria-describedby={repository}
              aria-live="polite"
              aria-labelledby={repository}
              inputProps={{
                className: "input-group",
                "aria-haspopup": "true",
                "aria-live": "polite",
                "aria-atomic": "true",
                "aria-label": "repository",
                labelkey: "name",
              }}
              shouldSelect={true}
            />
          )}
        </div>
        <div className="col-md-4">
          <label
            htmlFor="title"
            className="col-form-label"
            aria-labelledby="title"
          >
            Title
          </label>
          {!validateTitle ? (
            <p className="quoteError">{findErrorMsg(titleError)}</p>
          ) : null}
          <input
            {...bindTitle}
            id="title"
            name="title"
            type="text"
            className="form-control title"
            value={bindTitle.value}
          />
        </div>

        <div className="col-md-4">
          <label
            htmlFor="generalField"
            className="col-form-label"
            aria-labelledby="subject"
          >
            Search All
          </label>
          {!validateGeneralField ? (
            <p className="quoteError">{findErrorMsg(generalFieldError)}</p>
          ) : null}
          <input
            {...bindGeneralField}
            id="generalField"
            name="generalField"
            type="text"
            className="form-control generalField"
          />
        </div>
        <div className="col-md-4">
          <label
            htmlFor="creator"
            className="col-form-label"
            aria-labelledby="creator"
          >
            Creator
          </label>
          {!validateCreator ? (
            <p className="quoteError">{findErrorMsg(creatorError)}</p>
          ) : null}
          <input
            {...bindCreator}
            id="creator"
            name="creator"
            type="text"
            className="form-control creator"
          />
        </div>
        <div className="col-md-4">
          <label
            htmlFor="geographic_area"
            className="col-form-label"
            aria-labelledby="geographic_area"
          >
            Geographic Area
          </label>
          {!validateGeographicArea ? (
            <p className="quoteError">{findErrorMsg(geoAreaError)}</p>
          ) : null}
          <input
            {...bindGeographicArea}
            id="geographic_area"
            name="geographic_area"
            type="text"
            className="form-control geographic_area"
          />
        </div>
        <div className="col-md-4">
          <label
            htmlFor="subject"
            className="col-form-label"
            aria-labelledby="subject"
          >
            Subject (Topic)
          </label>
          {!validateSubject ? (
            <p className="quoteError">{findErrorMsg(subjectError)}</p>
          ) : null}
          <input
            {...bindSubject}
            id="subject"
            name="subject"
            type="text"
            className="form-control subject"
          />
        </div>
        <div className="col-md-4">
          <label
            htmlFor="subjectPerson"
            className="col-form-label"
            aria-labelledby="subjectPerson"
          >
            Subject (Person)
          </label>
          {!validateSubjectPerson ? (
            <p className="quoteError">{findErrorMsg(subjectErrorPerson)}</p>
          ) : null}
          <input
            {...bindSubjectPerson}
            id="subjectPerson"
            name="subjectPerson"
            type="text"
            className="form-control subjectPerson"
          />
        </div>
        <div className="col-md-4">
          <label
            htmlFor="subjectOrganization"
            className="col-form-label"
            aria-labelledby="subjectOrganization"
          >
            Subject (Organization)
          </label>
          {!validateSubjectOrganization ? (
            <p className="quoteError">
              {findErrorMsg(subjectErrorOrganization)}
            </p>
          ) : null}
          <input
            {...bindSubjectOrganization}
            id="subjectOrganization"
            name="subjectOrganization"
            type="text"
            className="form-control subjectOrganization"
          />
        </div>
        <div className="form-check digital-checkbox mb-3">
          <input
            id="digital_object"
            name="digital_object"
            type="checkbox"
            aria-label={digital ? "check" : "unchecked"}
            className="form-check-input digital_object"
            checked={digital}
            onClick={() => setDigital(!digital)}
            onChange={() => setDigital(!digital)}
          />
          <label
            htmlFor="digital_object"
            className="form-check-label tooltips"
            aria-label={`digital_object ${digitalToolTip}`}
            data-toggle="tooltip"
            data-placement="bottom"
            title={digitalToolTip}
            role="tooltip"
            delay={{ show: 500, hide: 100 }}
            tabIndex="0"
          >
            Restrict to Digital Objects
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        {error ? <p className="quoteError">&#10033;</p> : null}
        <button
          value="Submit Search"
          type="submit"
          className="btn btn-primary dark-bg"
          disabled={error}
        >
          Search
        </button>
        <button
          label="Reset Search"
          type="submit"
          className="btn btn-danger ml-3"
          onClick={handleResetSearch}
        >
          Reset
        </button>
      </div>
    </form>
  );
}

export default withRouter(connect(null, { searchFindingAids })(Form));
