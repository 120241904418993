import React, { useState, useEffect, useRef } from "react";
import { facetFindingAids } from "../../../actions/facetFindingAids";
import { withRouter, useLocation } from "react-router-dom";
import Xicon from "../../../images/x-lg.svg";
import { connect } from "react-redux";

function Facets(props) {
  const { repositoryData } = props;
  const [curFacets, setCurFacets] = useState(<div></div>);
  const [selected, setSelected] = useState([]);
  const [titleToggle, setToggleTitle] = useState(false);
  const location = useLocation().pathname;
  const facetRef = useRef(null);

  const facetNames = {
    repository: "Repository",
    languages: "Languages",
    start_dates: "Start Date",
    end_dates: "End Date",
    subject_topics: "Subject (Topic)",
    subject_persons: "Subject (Person)",
    subject_organizations: "Subject (Organization)",
    geographic_areas: "Geographic Area",
  };

  const searchItems = ["creators=", "geographic_areas=","text=", "repository=", "subject_topics=", "subject_persons=", "subject_organizations=", "title=", "digital="]

  function noSearch() {
    let all_missing = true;
    for (let item in searchItems){
      if (location.includes(item)) {
        all_missing = false; 
      }
    }

    if (all_missing) {
      props.history.push("/search/:query");
    }
  };

  function getRepoName(abbr) {
    for (const i in repositoryData) {
      let getRepo = repositoryData[i];
      if (getRepo.abbr_name === abbr) {
        return getRepo.name;
      }
    }
  }

  function buildFacetList(str) {
    if (props.facets[str] !== undefined) {
      const facets = props.facets[str].filter((data) => data.count !== 0);
      //these are the nested items generated under each facet type.
      const handleKeyDownChild = (e, str, data, i) => {
        let addQuery = `${str}=${data.text}`;
        if (e.key === "Enter") {
          for (const i in selected) {
            if (addQuery === selected[i]) {
              // Remove the facet here because it's already selected
              if (selected.length > 1) {
                selected.splice(i, 1);
                props.history.push(selected.join("&"));
              } else {
                props.facetFindingAids("");
                noSearch(); 
              }
              return;
            }
          }
          if (selected.length >= 1) {
            let breakUp = selected.join("&");
            addQuery = `${breakUp}&${addQuery}`;
          }
          addQuery = addQuery.replace(":", "=");
          props.history.push(`/search/${addQuery.replace("params=", "")}`);
        } else if (e.key === "Backspace") {
          let newQuery = location.replace(addQuery, "");
          if (newQuery === "/search/") {
            newQuery = "/search/:query";
          }
          props.history.push(newQuery);
        }
      };
      return facets.map((data, i) => {

        const labelText =
          str === "repository" ? getRepoName(data.text) : data.text;

        return (
          <div
            className="ml-3"
            key={i}
            role="listitem"
            aria-controls={`${data.narrow_url}`}
            onKeyDown={(e) => handleKeyDownChild(e, str, data, i)}
          >
            <a
              id={`${data.narrow_url}`}
              href={`#${data.narrow_url}`}
              onClick={(e) => {
                e.preventDefault();
                let addQuery = `${str}=${data.text}`;
                for (const i in selected) {
                  if (addQuery === selected[i]) {
                    // Remove the facet here because it's already selected
                    if (selected.length > 1) {
                      selected.splice(i, 1);
                      props.history.push(selected.join("&"));
                    } else {
                      props.facetFindingAids("");
                      noSearch(); 
                    }
                    return;
                  }
                }
                if (selected.length >= 1) {
                  let breakUp = selected.join("&");
                  addQuery = `${breakUp}&${addQuery}`;
                }
                addQuery = addQuery.replace(":", "=");
                props.history.push(`/search/${addQuery.replace("params=", "")}`);
              }}
            >
              {labelText} ({data.count}){" "}
              {selected.indexOf(`${str}=${data.text}`) !== -1 && (
                <img src={Xicon} className="icon" alt="remove facet icon" />
              )}
            </a>
          </div>
        );
      });
    } else {
      //TODO: this is an invalid search url. add redirect but consider looping issues
    }
  }

  useEffect(() => {
    const url = location.split("/search/");
    const params = url[url.length - 1].split("&");
    let currentFacets = [];

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        setToggleTitle(!titleToggle);
      }
    };

    for (const i in params) {
      if (params[i] == ":query") {
        break;
      }
      params[i] = params[i].replace("_exact%3A", "=");
      params[i] = params[i].replace("%3A", "=");
      currentFacets.push(params[i]);
    }
    setSelected(currentFacets);

    if (Object.keys(props.facets).length > 0) {
      const buildFacets = (
        <div>
          {Object.entries(facetNames).map(([key, value], i) => {
            return (
              <div className="card" key={i} ref={facetRef}>
                <a
                  className="facet-entry"
                  data-bs-toggle="collapse"
                  href={`#${key}FacetDropdown`}
                  role="button"
                  aria-expanded={titleToggle}
                  aria-controls={`${key}FacetDropdown`}
                  onClick={() => {
                    facetRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }}
                >
                  <p className="card card-title dark-bg p-2 text-light m-0 facet-headers">
                    {value}
                  </p>
                </a>
                <div
                  className="facet-dropdown collapse multi-collapse my-1"
                  id={`${key}FacetDropdown`}
                  onKeyDown={(e) => handleKeyDown(e)}
                  role="listbox"
                >
                  {buildFacetList(key)}
                </div>
              </div>
            );
          })}
        </div>
      );
      setCurFacets(buildFacets);
    } else {
      setCurFacets(<div></div>);
    }
  }, [props.facets]);

  return <div>{curFacets}</div>;
}

const mapStateToProps = (state) => ({
  facets: state.facets.facets,
});

export default withRouter(
  connect(mapStateToProps, {
    facetFindingAids,
  })(Facets)
);
