import React from "react";
import ArchivesStats from "./ArchivesStats";

function ArchivesStatsPage() {
  document.title = "Archives Statistics - TARO";
  return (
    <div>
      <ArchivesStats />
    </div>
  );
}

export default ArchivesStatsPage;
