function ApiTitle() {
  return (
    <>
      <center>
        <br />
        <h1 className="card-title">TARO Search API</h1>
      </center>
      <p className="card-body my-0 lead text-center">
        Data displayed on this site is obtained by querying the TARO Search API.
        This API is also available to query directly and was designed with
        researchers and data harvesters in mind.
      </p>
      <center>
        <p className="card-text">
          <em>
            Anonymous requests are currently limited to 100 per hour. For usage
            that will exceed that limit, please{" "}
            <a
              href="http://texastaro.pbworks.com/w/page/73447661/TARO%20wiki%20home"
              target="_blank"
            >
              contact the TARO Committee
            </a>{" "}
            and request an API Token.
          </em>
        </p>
      </center>
    </>
  );
}

export default ApiTitle;
