import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { searchFindingAids } from "../../actions/searchFindingAids";
import { facetFindingAids } from "../../actions/facetFindingAids";
import { resetFindingAids } from "../../actions/resetFindingAids";
import SearchResults from "./SearchResults";
import AdvancedSearch from "./AdvancedSearch/index";
import Faceting from "./Faceting/index";
import Email from "../../images/envelope.svg";
import { scrollToTop } from "../Utils/ScrollToTop";

function SearchResultsView(props) {
  const [query, setQuery] = useState("");
  const { repositoryData, findingAids } = props;
  const [toggle, setToggle] = useState(false);
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  const [top, setTop] = useState(0);
  const [headerOffset, setHeaderOffset] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(null); 
  const location = useLocation();
  const advancedRef = useRef(null);
  const resultsRef = useRef(null);
  const totalResults = findingAids.findingAids.length;

  document.title = "TARO - Search";

  //regular window size, on any change, go to the top 
  useEffect(() => {
    scrollToTop();
  }, [window.history.pathname]);

  //MOBILE EFFECTS
  //look at the window size on current device, but should be responsive
  const updateSize = () => {
    setSize([window.innerWidth, window.innerHeight]);
  };

  //get ref information for head on the results of a search, so we know where on the page we need to go UNDER.
  const findOffsetScroll = (input) => {
    setHeaderOffset(input);
  };

  //make sure if the window changes sizes, we resize
  useEffect(() => {
    // console.log("listening");
    window.addEventListener("resize", updateSize);
  }, [window.innerWidth, window.innerHeight]);

  //find scroll location for mobile.
  const scrollTo = useCallback(
    (e) => {
      //verify the screen is small enough, this action is even required.
      if (totalResults === 0 && location.pathname === "/search/:query") {
        //if no search has occurred, we want to go to the top of the advanced search
        advancedRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else {
        if (resultsRef !== null && headerOffset !== null) {
          //make sure all content has finished rendering so we know where to go.
          if (resultsRef.current !== null && headerOffset.current !== null) {
            // console.log("time to find location", headerOffset);
            setTop(
              headerOffset.current.offsetHeight +
                resultsRef.current.offsetHeight
            );
          }
        }
        // console.log("top ?", top);
        resultsRef.current.scrollBy({ top: top });
        resultsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    [resultsRef, headerOffset, totalResults]
  );

  useEffect(() => {
    if (size[0] < 1000 || (size[1] < 1000 && size !== [""])) {
      scrollTo();
    } 
  }, [size, findingAids, page]);

  //actions related to component results and information
  useEffect(() => {
    resetFindingAids();
    scrollToTop();
  }, []);

  useEffect(() => {
    if (totalResults === 0) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [totalResults]);

  const updateLoading = (input) => {
    setLoading(input);
  };

  useEffect(() => {
    setLoading(true);
    const queryDetails = location.pathname.split("/search/");
    const queryParams = queryDetails[queryDetails.length - 1];

    if (queryParams !== "") {
      if (queryParams === ":query") {
        props.facetFindingAids("");
      } else {
        let facetString = [];
        const buildFacets = queryParams.split("&");
        for (const i in buildFacets) {
          facetString.push(`params=${buildFacets[i].replace("=", ":")}`);
        }
        facetString = facetString.join("&");
        props.facetFindingAids(facetString);
      }
      // console.log('require params',queryParams);
      props.searchFindingAids(queryParams);
    }
    setQuery(queryParams);
    setLoading(true);
  }, [query, location]);

  const handleToggle = (e) => {
    e.preventDefault();
    setToggle(!toggle);
  };

  // console.log('loading?', loading)

  return (
    <div>
      <center>
        <h1>Search</h1>
      </center>
      <div className="d-flex mb-4 ml-3">
        <button
          onClick={handleToggle}
          className={`${toggle ? "btn btn-dark" : "btn btn-light"}`}
        >
          {toggle ? "Hide Advanced Search" : "Show Advanced Search"}
        </button>
      </div>
      <div ref={advancedRef}>
        {toggle ? <AdvancedSearch query={query} /> : null}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-xs-12">
            <Faceting repositoryData={repositoryData} />
          </div>
          <div ref={resultsRef} className="col-lg-9 col-xs-12">
            <SearchResults
              repositoriesList={repositoryData}
              scrolling={findOffsetScroll}
              updateLoading={updateLoading}
              loading={loading}
              setPage={setPage}
            />
          </div>
        </div>
      </div>

      <br />
      {/* <center>
        <a
          href={`mailto:lib-taro@email.com`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us&nbsp;
          <img src={Email} className="icon" alt="email icon" />
        </a>
      </center> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  repositoryData: state.repository.repositoryData,
  findingAids: state.findingAids,
});

export default connect(mapStateToProps, {
  searchFindingAids,
  facetFindingAids,
})(SearchResultsView);
