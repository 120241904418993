import { useState } from "react";

export const useInputText = (originalValue) => {
  const [value, setValue] = useState(originalValue);
  const [valid, setValidate] = useState(true);
  const [errorReason, setErrorReason] = useState(null);

  const handleValidation = (text) => {
    let clean = text.trim();
    let quoteCount = 0;
    for (let i in text) {
      if (clean[i] === `"`) {
        quoteCount = quoteCount + 1;
      }
    }

    if (quoteCount > 0) {
      if (quoteCount % 2 !== 0) {
        setValidate(false);
        setErrorReason("odd");
      } else {
        //will be used for exact searches in the future, leave error for now.
        if (clean[0] !== `"` || clean[clean.length - 1] !== `"`) {
          setValidate(false);
          setErrorReason("location");
        } else {
          setValidate(true);
          setErrorReason(null);
        }
      }
      quoteCount = 0;
    }

    if (clean === "") {
      setValidate(true);
      setErrorReason(null);
    }
  };

  return {
    value,
    setValue,
    reset: () => {
      setValidate(true);
      setValue("");
      setErrorReason(null);
    },
    bind: {
      value,
      onChange: (e) => {
        if (typeof e === "string") {
          handleValidation(e);
          setValue(e);
        } else {
          handleValidation(e.target.value);
          setValue(e.target.value);
        }
      },
    },
    valid,
    errorReason,
  };
};
