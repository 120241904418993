import React from "react";

function GoogleMap() {
  // const mapLink = '<iframe src="https://www.google.com/maps/d/embed?mid=1je2EXPOKYDPV4yHvNBc8gMfWYia6Bdcb" width="640" height="480"></iframe>'

  return (
    <>
      <div className="container-fluid" id="map-container">
        <center>
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/d/embed?mid=1LEJGyfAx-novjbOsiM27Z37Q_sKTWrJE"
            width="640"
            height="480"
          ></iframe>
        </center>
      </div>
    </>
  );
}

export default GoogleMap;
