import React from "react";
import LogoUTL from "./LogoUTL";

function FooterDisplay() {
  return (
    <footer className="mt-auto py-3">
      <div className="container-fluid">
        <ul className="list-group list-group-flush text-center">
          <li className="list-group-item dark-bg">
            <div className="footer-copyright">
              Web Design Copyright © The University of Texas at Austin.
            </div>
          </li>
          <li className="list-group-item">
            <div>
              Web Design Produced by The University of Texas at Austin Libraries
              <center>
                <LogoUTL />
              </center>
            </div>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default FooterDisplay;
