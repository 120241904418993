import axios from "axios";
import { SEARCH_REPOSITORIES } from "./types";
import { tokenConfig } from "./auth";

// GET REPOSITORY SPECIFIC INFO
export const searchRepositories = (query) => (dispatch, getState) => {
  axios
    .get(
      `${process.env.REACT_APP_API_BASE}/api/repository/search/?${query}`,
      tokenConfig(getState)
    )
    .then((response) => {
      if (response.status === 200 && response.data.length === 0) {
        console.log("Repositories are not available from search API");
      } else {
        const repoSort = () => {
          const list = response.data.sort((a, b) => {
            const aCompare = a.name.trim().toUpperCase();
            const bCompare = b.name.trim().toUpperCase();

            return aCompare < bCompare ? -1 : aCompare > bCompare ? 1 : 0;
          });

          return list;
        };

        const repositories = repoSort();

        dispatch({
          type: SEARCH_REPOSITORIES,
          payload: [...repositories],
        });
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 404) {
          window.location.href = "/not_found";
        } else if (err.response.status === 500) {
          window.location.href = "/system_down";
        } else {
          console.log(err);
        }
      }
      // console.log("err", err);
    });
};
