import React from "react";
import { Link } from "react-router-dom";

function ArchivesStats() {
  document.title = "Legacy Statistics - TARO";
  return (
    <div>
      <center>
        <h1>Archives - Legacy Statistics</h1>
      </center>
      <p></p>
      <div className="archives_page">
        <p>
          <strong>
            <Link to={`/archives`}>Return to Phase One</Link>
          </strong>
        </p>
        <p>
          <strong>
            <a href="https://github.com/txarchives/archives/tree/main/statistics/all_TARO_stats">
              All TARO Statistics
            </a>
          </strong>
        </p>
        <p>
          TARO statistics by year, 2001-Oct 2021 <br />
          formats vary since multiple statistics applications were used during
          that period.
        </p>
        <p></p>
        <p>
          <strong>
            <a href="https://github.com/txarchives/archives/tree/main/statistics/TARO_stats_by_Repository">
              TARO Statistics by Repository
            </a>
          </strong>
          <p>We only have stats for "by Repository" from 2012-2021</p>
        </p>
        <div className="stats_table">
          <table>
            <thead>
              <tr>
                <th className="stats_col1">Account Name</th>
                <th>Institution/Repository Name</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="stats_col1">aalgs</td>
                <td>African American Library at the Gregory School</td>
              </tr>
              <tr>
                <td className="stats_col1">aasai</td>
                <td>Anomaly Archives of the Scientific Anomaly Institute</td>
              </tr>
              <tr>
                <td className="stats_col1">apts</td>
                <td>Austin Presbyterian Theological Seminary</td>
              </tr>
              <tr>
                <td className="stats_col1">aushc</td>
                <td>Austin History Center, Austin Public Library</td>
              </tr>
              <tr>
                <td className="stats_col1">bayabl</td>
                <td>Baylor University, Armstrong Browning Library</td>
              </tr>
              <tr>
                <td className="stats_col1">baykc</td>
                <td>
                  Baylor University, Keston Center for Religion, Politics, and
                  Society
                </td>
              </tr>
              <tr>
                <td className="stats_col1">baypll</td>
                <td>Baylor University, W.R. Poage Legislative Library</td>
              </tr>
              <tr>
                <td className="stats_col1">baytc</td>
                <td>Baylor University, The Texas Collection</td>
              </tr>
              <tr>
                <td className="stats_col1">carcht</td>
                <td>Catholic Archives of Texas</td>
              </tr>
              <tr>
                <td className="stats_col1">concordia</td>
                <td>Concordia University Texas Historical Online Collection</td>
              </tr>
              <tr>
                <td className="stats_col1">dalpub</td>
                <td>
                  Texas/Dallas History and Archives Division, Dallas Public
                  Library
                </td>
              </tr>
              <tr>
                <td className="stats_col1">drtsa</td>
                <td>Daughters of the Republic of Texas Library at the Alamo</td>
              </tr>
              <tr>
                <td className="stats_col1">efcl</td>
                <td>Emily Fowler Central Library, Denton Public Library</td>
              </tr>
              <tr>
                <td className="stats_col1">epbhc</td>
                <td>El Paso Public Library, Border Heritage Center</td>
              </tr>
              <tr>
                <td className="stats_col1">fwjarch</td>
                <td>Fort Worth Jewish Archive</td>
              </tr>
              <tr>
                <td className="stats_col1">fwpl</td>
                <td>Fort Worth Public Library</td>
              </tr>
              <tr>
                <td className="stats_col1">hamtmc</td>
                <td>
                  Houston Academy of Medicine-Texas Medical Center Library, John
                  P. McGovern Historical Collections and Research Center
                </td>
              </tr>
              <tr>
                <td className="stats_col1">hcarch</td>
                <td>Harris County Archives</td>
              </tr>
              <tr>
                <td className="stats_col1">houpub</td>
                <td>
                  Houston Public Library, Houston Metropolitan Research Center
                </td>
              </tr>
              <tr>
                <td className="stats_col1">hrdi</td>
                <td>UT Human Rights Documentation Initiative</td>
              </tr>
              <tr>
                <td className="stats_col1">lamar</td>
                <td>Lamar University's Archives and Special Collections</td>
              </tr>
              <tr>
                <td className="stats_col1">mcarch</td>
                <td>Menil Collection Archives</td>
              </tr>
              <tr>
                <td className="stats_col1">mfah</td>
                <td>Museum of Fine Arts Houston</td>
              </tr>
              <tr>
                <td className="stats_col1">ojac</td>
                <td>
                  Robert E. Nail, Jr. Historical Archives at Old Jail Art Center
                </td>
              </tr>
              <tr>
                <td className="stats_col1">pphmrc</td>
                <td>Panhandle-Plains Historical Museum Research Center</td>
              </tr>
              <tr>
                <td className="stats_col1">ricewrc</td>
                <td>
                  Rice University, Fondren Library, Woodson Research Center
                </td>
              </tr>
              <tr>
                <td className="stats_col1">sama</td>
                <td>San Antonio Municipal Archives</td>
              </tr>
              <tr>
                <td className="stats_col1">sapl</td>
                <td>San Antonio Public Library</td>
              </tr>
              <tr>
                <td className="stats_col1">sjmh</td>
                <td>
                  Albert and Ethel Herzstein Library, San Jacinto Museum of
                  History
                </td>
              </tr>
              <tr>
                <td className="stats_col1">smu</td>
                <td>Southern Methodist University</td>
              </tr>
              <tr>
                <td className="stats_col1">stthomas</td>
                <td>University of St. Thomas Archives</td>
              </tr>
              <tr>
                <td className="stats_col1">swcpc</td>
                <td>
                  Texas Tech University Southwest Collection/Special Collections
                  Library
                </td>
              </tr>
              <tr>
                <td className="stats_col1">tamucc</td>
                <td>Texas A & M Corpus Christi</td>
              </tr>
              <tr>
                <td className="stats_col1">tamucush</td>
                <td>Texas A & M University Cushing Memorial Library</td>
              </tr>
              <tr>
                <td className="stats_col1">tamuk</td>
                <td>
                  South Texas Archives at Texas A & M University-Kingsville
                </td>
              </tr>
              <tr>
                <td className="stats_col1">tcusp</td>
                <td>Texas Christian University’s Special Collections</td>
              </tr>
              <tr>
                <td className="stats_col1">tslac</td>
                <td>Texas State Library and Archives Commission</td>
              </tr>
              <tr>
                <td className="stats_col1">tsusm</td>
                <td>Texas State University</td>
              </tr>
              <tr>
                <td className="stats_col1">ttuav</td>
                <td>
                  Texas Tech University Southwest Collection/Special Collections
                  Library
                </td>
              </tr>
              <tr>
                <td className="stats_col1">tturb</td>
                <td>
                  Texas Tech University Southwest Collection/Special Collections
                  Library
                </td>
              </tr>
              <tr>
                <td className="stats_col1">ttusw</td>
                <td>
                  Texas Tech University Southwest Collection/Special Collections
                  Library
                </td>
              </tr>
              <tr>
                <td className="stats_col1">ttuua</td>
                <td>
                  Texas Tech University Southwest Collection/Special Collections
                  Library
                </td>
              </tr>
              <tr>
                <td className="stats_col1">ttuva</td>
                <td>Vietnam Center and Archive, Texas Tech University</td>
              </tr>
              <tr>
                <td className="stats_col1">twu</td>
                <td>Texas Woman's University, the Woman's Collection</td>
              </tr>
              <tr>
                <td className="stats_col1">txglo</td>
                <td>Texas General Land Office Archives and Records</td>
              </tr>
              <tr>
                <td className="stats_col1">tyrrell</td>
                <td>Tyrrell Historical Library</td>
              </tr>
              <tr>
                <td className="stats_col1">uhsc</td>
                <td>University of Houston Libraries, Special Collections</td>
              </tr>
              <tr>
                <td className="stats_col1">uhua</td>
                <td>University of Houston Libraries, Special Collections</td>
              </tr>
              <tr>
                <td className="stats_col1">uhwarc</td>
                <td>University of Houston Libraries, Special Collections</td>
              </tr>
              <tr>
                <td className="stats_col1">unt</td>
                <td>University of North Texas Archives</td>
              </tr>
              <tr>
                <td className="stats_col1">untmlsc</td>
                <td>
                  University of North Texas Music Library Special Collections
                </td>
              </tr>
              <tr>
                <td className="stats_col1">utaaa</td>
                <td>
                  The University of Texas at Austin. Alexander Architectural
                  Archive
                </td>
              </tr>
              <tr>
                <td className="stats_col1">utarl</td>
                <td>
                  University of Texas Arlington Library, Special Collections
                </td>
              </tr>
              <tr>
                <td className="stats_col1">utcah</td>
                <td>
                  The University of Texas at Austin. Dolph Briscoe Center for
                  American History
                </td>
              </tr>
              <tr>
                <td className="stats_col1">utep</td>
                <td>University of Texas El Paso</td>
              </tr>
              <tr>
                <td className="stats_col1">uthrc</td>
                <td>
                  Harry Ransom Humanities Research Center, University of Texas
                  at Austin
                </td>
              </tr>
              <tr>
                <td className="stats_col1">uthscsa</td>
                <td>UT Health Science Center San Antonio</td>
              </tr>
              <tr>
                <td className="stats_col1">utlac</td>
                <td>
                  The University of Texas at Austin. Benson Latin American
                  Collection
                </td>
              </tr>
              <tr>
                <td className="stats_col1">utlaw</td>
                <td>Tarlton Law Library, University of Texas at Austin</td>
              </tr>
              <tr>
                <td className="stats_col1">utlsc</td>
                <td>
                  H.J. Lutcher Stark Center, University of Texas at Austin
                </td>
              </tr>
              <tr>
                <td className="stats_col1">utmb</td>
                <td>
                  Truman G. Blocker, Jr. History of Medicine Collections, Moody
                  Medical Library, University of Texas Medical Branch
                </td>
              </tr>
              <tr>
                <td className="stats_col1">utmda</td>
                <td>University of Texas M.D. Anderson Cancer Center</td>
              </tr>
              <tr>
                <td className="stats_col1">utsa</td>
                <td>University of Texas San Antonio</td>
              </tr>
              <tr>
                <td className="stats_col1">uttyler</td>
                <td>
                  University Archives and Special Collections The University of
                  Texas at Tyler
                </td>
              </tr>
              <tr>
                <td className="stats_col1">vpaut</td>
                <td>Vertebrate Paleontology Archives (VPA) at UT Austin</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ArchivesStats;
