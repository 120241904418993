import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { searchCreators } from "../../actions/searchCreators";
import { Link } from "react-router-dom";
import ResultsHeader from "./ResultsHeader";
import Loading from "../Utils/Loading";
import { scrollToTop } from "../Utils/ScrollToTop";

function CreatorList(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [pageCount, setPageCount] = useState("");
  const [creatorsList, setCreatorsList] = useState([]); //information from state
  const [sortedCreators, setSortedCreators] = useState([]); //list from creators sorted in alpha order
  const [filtering, setFiltering] = useState(false);
  const [filterBy, setFilterBy] = useState("");
  const [filteredList, setFiltersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { creators } = props;
  //onload change creator list
  useEffect(() => {
    setLoading(true);
    if (props.creators.length > 0) {
      setCreatorsList(creators);
    }
    setLoading(false);
  }, [creators]);

  //sort and alphabetize list
  useEffect(() => {
    setLoading(true);
    if (creatorsList.length > 0) {
      // Converts data from array of objects to array of strings
      let data = creatorsList.map((cObj) => {
        if (cObj.name) {
          return cObj.name;
        } else {
          return " ";
        }
      });
      data = data.sort((a, b) => (a > b ? 1 : -1));
      setSortedCreators(data);
    }
    setLoading(false);
  }, [creatorsList]);

  //reset the page
  useEffect(() => {
    setLoading(true);
    setCurrentPage(0);
    setLoading(false);
  }, [perPage]);

  useEffect(() => {
    let list = [];
    setLoading(true);
    setCurrentPage(0);
    if (filtering) {
      sortedCreators.forEach((creator) => {
        if (creator[0] === filterBy) {
          list.push(creator);
        }
      });
      setFiltersList([...list]);
      setCreatorsList([]);
      //update page count based on results from filters so the page should not exceed list
    } else {
      setCreatorsList(creators);
      setFiltersList([]);
      //reset back to original state
    }
    // updatePaginate();
    setLoading(false);
  }, [filterBy, filtering]);

  //changing what you are filtering by or if filtering at all, reset current page
  const offset = currentPage * perPage;

  function findPerPageCount(listLength) {
    if (listLength > 0) {
      let pageTotal = Math.ceil(listLength / perPage);
      setPageCount(pageTotal);
    }
  }

  useEffect(() => {
    if (filtering) {
      findPerPageCount(filteredList.length);
    } else {
      findPerPageCount(sortedCreators.length);
    }
  }, [sortedCreators, filteredList]);

  useEffect(() => {
    if (filtering) {
      findPerPageCount(filteredList.length);
    } else {
      findPerPageCount(sortedCreators.length);
    }
  }, [perPage]);

  function handlePageClick({ selected: selectedPage }) {
    props.setPage(selectedPage); 
    setCurrentPage(selectedPage);
    scrollToTop();  
  }


  function handleFilter(letter) {
    setLoading(true);
    if (letter !== "") {
      setCurrentPage(0);
      setFilterBy(letter);
      setFiltering(true);
    } else {
      //settings
      setCurrentPage(0);
      setFilterBy("");
      setFiltering(false);
      setFiltersList([]);
      setCreatorsList(creators);
    }
    setLoading(false);
  }

  function handleLoading(set) {
    setLoading(set);
  }

  const currentPageData =
    sortedCreators && sortedCreators.length > 0 && !loading ? (
      sortedCreators.slice(offset, offset + perPage).map((creator, i) => (
        <div className="creator-link card" key={i}>
          <div className="card-body">
            <h5 className="card-title">
              <Link to={`/search/creators=${creator}`}>{creator}</Link>
            </h5>
          </div>
        </div>
      ))
    ) : (
      <Loading results={sortedCreators} from="browseByCreator" />
    );

  const currentPageFilter =
    filteredList.length > 0 ? (
      filteredList.slice(offset, offset + perPage).map((creator, i) => (
        <div className="repository-link card" key={i}>
          <div className="card-body">
            <h5 className="card-title">
              <Link to={`/search/creators=${creator}`}>{creator}</Link>
            </h5>
          </div>
        </div>
      ))
    ) : loading ? (
      <Loading results={sortedCreators} from="browseByCreator" />
    ) : (
      <div className="browse-by-link card">
        <div className="card-body">
          <h5 className="card-title">
            Sorry, there aren't any creators starting with {filterBy}
          </h5>
        </div>
      </div>
    );

  const total = sortedCreators.length;
  const display = filtering ? filteredList.length : sortedCreators.length;

  const displayCount = perPage * (currentPage + 1);

  return (
    <div>
      {total > 0 ? (
        <div>
          <ResultsHeader
            props={props}
            total={total}
            display={display}
            pageNumber={currentPage}
            perPage={perPage}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
            terms={creatorsList}
            filtering={setFiltering}
            setFiltering={handleFilter}
            setLoading={handleLoading}
            from="creators"
          />
          {filtering === false ? currentPageData : currentPageFilter}
          {display / displayCount > 0 && display > perPage ? (
            <ReactPaginate
              previousLabel={"← Previous"}
              nextLabel={"Next →"}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              forcePage={currentPage}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              disabledClassName={"page-item disabled"}
              activeClassName={"page-item active"}
            />
          ) : null}
        </div>
      ) : (
        <Loading results={sortedCreators} from="browseByCreator" />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  creators: state.creators.creators,
});

export default connect(mapStateToProps, { searchCreators })(CreatorList);
