import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ResultsHeader from "./ResultsHeader";
import Loading from "../Utils/Loading";

function AllowedList(props) {
  const [browseTerm, setBrowseTerm] = useState("");
  const [browseList, setBrowseList] = useState([]);
  const [link, setLink] = useState("");
  const [sortedList, setSortedList] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [filterBy, setFilterBy] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const location = window.location.pathname;
  const [loading, setLoading] = useState(true);

  // console.log("location", location);

  useEffect(() => {
    setLoading(true);
    if (location === "/browse/repositories") {
      if (props.repositoryData.length > 0) {
        setBrowseTerm("repositories");
        setBrowseList(props.repositoryData);
      }
    } else {
      if (props.allowedList.length > 0) {
        console.log("about the allowed list", props.allowedList);
        setBrowseTerm(props.allowedList[0].label);
        if (browseList !== "repository") {
          let list = props.allowedList[0].browse_terms;
          // make sure the order is alphabetical
          list.sort((a, b) => (a > b ? 1 : -1));
          setBrowseList(list);
        }
      }
    }
    setLoading(false);
  }, [props.allowedList, browseList, props.repositoryData, location]);

  useEffect(() => {
    const switchTerm = (term) => {
      // console.log("term", term);
      switch (term) {
        case "repositories":
          return setLink("/search/repository=");
        case "Geographic Areas":
          return setLink("/search/geographic_areas=");
        case "Subjects":
          return setLink("/search/subject_topics=");
        default:
          return setLink("");
      }
    };

    switchTerm(browseTerm);
  }, [browseTerm]);

  useEffect(() => {
    //everything put repositories will already be in alpha order
    setLoading(true);
    if (browseTerm !== "repositories") {
      if (browseList.length > 0) {
        const data = browseList.map((item) => {
          if (item) {
            return item;
          }
        });
        data.sort((a, b) => (a > b ? 1 : -1));
        setSortedList(data);
      }
    } else {
      setSortedList(browseList);
    }
    setLoading(false);
  }, [browseList]);

  useEffect(() => {
    let list = [];
    setLoading(true);
    if (filtering) {
      browseList.forEach((item) => {
        //have to look at name
        if (browseTerm === "repositories") {
          if (item.name[0].toUpperCase() === filterBy) {
            list.push(item);
          }
        } else {
          if (item[0].toUpperCase() === filterBy) {
            list.push(item);
          }
        }
      });

      setFilteredList([...list]);
    } else {
      setSortedList(browseList);
      setFilteredList([]);
    }

    setLoading(false);
  }, [filterBy, filtering]);

  function handleFilter(letter) {
    setLoading(true);
    if (letter !== "") {
      setFilterBy(letter);
      setFiltering(true);
    } else {
      setFilterBy("");
      setFiltering(false);
      setFilteredList([]);
      setSortedList(browseList);
    }
    setLoading(false);
  }

  function handleLoading(set) {
    setLoading(set);
  }

  useEffect(() => {
    handleFilter("");
  }, [props.link]);

  const currentPageData =
    sortedList &&
    sortedList.length &&
    !loading > 0 &&
    sortedList.map((term, i) => {
      let searchTerm = browseTerm === "repositories" ? term.abbr_name : term;
      let linkText = browseTerm === "repositories" ? term.name : term;

      return (
        <div className="browse-by-link card" key={i}>
          <div className="card-body">
            <p className="card-title results-header">
              <Link to={`${link}${searchTerm}`}>{linkText}</Link>
            </p>
          </div>
        </div>
      );
    });

  const filteredPageData =
    filtering && filteredList.length > 0 && !loading ? (
      filteredList.map((term, i) => {
        let searchTerm = browseTerm === "repositories" ? term.abbr_name : term;
        let linkText = browseTerm === "repositories" ? term.name : term;

        return (
          <div className="browse-by-link card" key={i}>
            <div className="card-body">
              <p className="card-title results-header">
                <Link to={`${link}${searchTerm}`}>{linkText}</Link>
              </p>
            </div>
          </div>
        );
      })
    ) : loading ? (
      <Loading results={browseList} from={`browseBy_${browseTerm}`} />
    ) : (
      <div className="browse-by-link card">
        <div className="card-body">
          <p className="card-title results-header">
            Sorry, there aren't any {browseTerm} starting with {filterBy}
          </p>
        </div>
      </div>
    );

  let total = browseList.length;
  let display = filtering ? filteredList.length : sortedList.length;

  return (
    <div>
      {total > 0 && !loading ? (
        <div>
          <ResultsHeader
            props={props}
            total={total}
            display={display}
            pageNumber={0}
            terms={browseList}
            filtering={setFiltering}
            setFiltering={handleFilter}
            from={browseTerm}
            setLoading={handleLoading}
          />

          {filtering === false ? currentPageData : filteredPageData}
        </div>
      ) : (
        <Loading results={browseList} from={`browseBy_${browseTerm}`} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  allowedList: state.allowedList.allowedList,
  repositoryData: state.repository.repositoryData,
});

export default connect(mapStateToProps)(AllowedList);
