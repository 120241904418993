import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { searchFindingAid } from "../../actions/searchFindingAid";
import { searchRepositories } from "../../actions/searchRepositories";
import ReactToPrint from "react-to-print";
import FindingAidSideBar from "./SideBar/index.js";
import PrintIcon from "../../images/printer.svg";
import ComponentToPrint from "./ComponentToPrint";
import CollectionSummary from "./CollectionSummary";
import CollectionDetails from "./CollectionDetails";
import CollectionRestrictions from "./CollectionRestrictions";
import AdministrativeInfo from "./AdministrativeInfo";
import OtherResources from "./OtherResources";
import SubjectTerms from "./SubjectTerms";
import OtherInformation from "./OtherInformation";
import Inventory from "./Inventory";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loading from "../Utils/Loading";

// Parent component for the display that includes main body (print content) and sidebar.
function FindingAidDisplay(props) {
  const componentRef = useRef();
  const [accessPt, setAccessPt] = useState([]);
  const [toggled, setToggled] = useState(false);
  const [summary, setSummary] = useState([]);
  const [details, setDetails] = useState([]);
  const [restrictions, setRestrictions] = useState([]);
  const [adminInfo, setAdminInfo] = useState([]);
  const [otherResources, setOtherResources] = useState([]);
  const [subjectTerm, setSubjectTerm] = useState([]);
  const [otherInfo, setOtherInfo] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [repoName, setRepoName] = useState([]);
  const [repoContact, setRepoContact] = useState([]);
  let passed = [];

  let results =
    Object.keys(props.findingAid).length > 0
      ? JSON.parse(props.findingAid[0].value)
      : null;
  // console.log("log", results);
  let repo_results =
    Object.keys(props.repositoryData).length > 0 ? props.repositoryData : null;

  const PrintLink = () => {
    return (
      <ReactToPrint
        trigger={() => (
          <button type="button" className="btn btn-link">
            Print / PDF{" "}
            <img src={PrintIcon} className="icon" alt="print icon" />
          </button>
        )}
        content={() => componentRef.current}
      />
    );
  };

  useEffect(() => {
    if (Object.keys(props.findingAid).length > 0) {
      const collectionSummaryData = results["collection summary"];
      const collectionDetailsData = results["collection details"];
      const collectionRestrictionsData = results["collection restrictions"];
      const adminInfoData = results["administrative information"];
      const otherResourcesData = results["other resources"];
      const subjectTermData = results["controlled access_terms"];
      const otherInformationData = results["other information"];
      const inventorySeries = results["inventory series"];

      // console.log("inventory series information ", inventorySeries);

      setSummary(collectionSummaryData);
      setDetails(collectionDetailsData);
      setRestrictions(collectionRestrictionsData);
      setAdminInfo(adminInfoData);
      setOtherResources(otherResourcesData);
      setSubjectTerm(subjectTermData);
      setOtherInfo(otherInformationData);
      setInventory(inventorySeries);
    }

    // console.log("details of inventory >> ", inventory);

    if (Object.keys(props.repositoryData).length > 0) {
      let currentRepo = window.location.pathname.split("/")[1];
      for (const repo of repo_results) {
        if (currentRepo === repo.abbr_name) {
          setRepoName(repo.name);
          setAccessPt(repo.access_link);
          setRepoContact(repo.external_email);
        }
      }
    }
  }, [props.findingAid]);

  const components = [
    {
      name: "Collection Summary",
      component: (
        <CollectionSummary list={summary} name="#collection_summary" />
      ),
      data: summary,
      anchor: "#collection_summary",
    },
    {
      name: "Collection Details",
      component: (
        <CollectionDetails list={details} name="#collection_details" />
      ),
      data: details,
      anchor: "#collection_details",
    },
    {
      name: "Collection Restrictions",
      component: (
        <CollectionRestrictions
          list={restrictions}
          name="#collection_restrictions"
        />
      ),
      data: restrictions,
      anchor: "#collection_restrictions",
    },
    {
      name: "Administrative Information",
      component: <AdministrativeInfo list={adminInfo} name="#admin_info" />,
      data: adminInfo,
      anchor: "#admin_info",
    },
    {
      name: "Other Resources",
      component: (
        <OtherResources list={otherResources} name="#other_resources" />
      ),
      data: otherResources,
      anchor: "#other_resources",
    },
    {
      name: "Subject Terms",
      component: <SubjectTerms list={subjectTerm} name="#subject_terms" />,
      data: subjectTerm,
      anchor: "#subject_terms",
    },
    {
      name: "Other Information",
      component: <OtherInformation list={otherInfo} name="#other_info" />,
      data: otherInfo,
      anchor: "#other_info",
    },
    {
      name: "Inventory",
      component: <Inventory list={inventory} name="#inventory" />,
      data: inventory,
      anchor: "#inventory",
    },
  ];

  const validate = (list) => {
    // console.log('list', list)
    for (let i = 0; i < list.length; i++) {
      let { data } = list[i];
      if (typeof data === "object" && data.length !== 0) {
        passed.push(list[i]);
      }
    }
  };

  validate(components);

  if (Object.keys(passed).length > 0) {
    return (
      <div
        className="container-fluid findingAidDisplay"
        id="finding-aid-display-all-content"
      >
        <div className="row container-fluid" id="finding-aid-subcontainer">
          <div
            className={`col-lg-4 collapse show sticky-top vh-100 ${
              toggled ? "show-sidebar" : "hide-sidebar"
            }`}
            id="sidebar"
          >
            <FindingAidSideBar
              citeInfo={adminInfo}
              access={accessPt}
              title={repoName}
              printLink={PrintLink()}
              passed={passed}
              toggled={toggled}
              setToggled={setToggled}
              contact={repoContact}
            />
          </div>
          <div
            className="col-lg-8 col-xs-12 justify-content-md-center"
            id="display-fields-content"
          >
            <ComponentToPrint
              ref={componentRef}
              toggled={toggled}
              setToggled={setToggled}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <Loading results={passed} from="finding aid" />;
  }
}

const mapStateToProps = (state) => ({
  findingAid: state.findingAid.findingAid,
  repositoryData: state.repository.repositoryData,
});

export default connect(mapStateToProps, {
  searchFindingAid,
  searchRepositories,
})(FindingAidDisplay);
