import { useEffect } from "react";
import { connect } from "react-redux";
import { searchFindingAids } from "../../actions/searchFindingAids";
import { withRouter } from "react-router-dom";

// Initial component for finding aid display - makes API call and sets the result to state through Redux.
function GetFindingAids(props) {
  useEffect(() => {
    const query = `repository=${props.match.params.repository}`;
    props.searchFindingAids(query);
  }, [props]);
  return null;
}

export default withRouter(connect(null, { searchFindingAids })(GetFindingAids));
