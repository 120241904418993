import { useEffect } from "react";
import Home from "./Home";
import { scrollToTop } from "../Utils/ScrollToTop";

function Landing() {
  document.title = "Texas Archival Resources Online";

  useEffect(() => {
    scrollToTop();
  }, [window.history.pathname]);

  return <Home />;
}

export default Landing;
