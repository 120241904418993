import { SEARCH_FINDINGAID } from "../actions/types";

const initialState = {
  findingAid: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_FINDINGAID:
      return {
        findingAid: action.payload,
      };
    default:
      // console.log('DEFAULT FINDING AIDS')
      return state;
  }
}
