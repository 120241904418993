import axios from "axios";
import { SEARCH_FINDINGAID } from "./types";
import { tokenConfig } from "./auth";

// SEARCH FINDING AID
//TODO:
// add redirect to not found for empty responses
export const searchFindingAid = (query) => (dispatch, getState) => {
  axios
    .get(
      `${process.env.REACT_APP_API_BASE}/api/finding_aid_display/search/?${query}`,
      tokenConfig(getState)
    )
    .then((response) => {
      if (response.status === 200 && response.data.length === 0) {
        window.location.href = "/not_found";
      } else {
        if (response.data.length >= 1) {
          response.data = response.data.splice(-1);
        }
        let objectLength = Object.keys(response.data).length
        if (objectLength > 1) {
          // account for an unexpected response that should be wrapped in array
          response.data = [response.data]
        }
        dispatch({
          type: SEARCH_FINDINGAID,
          payload: response.data,
        });
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 404) {
          window.location.href = "/not_found";
        } else if (err.response.state === 500) {
          window.location.href = "/system_down";
        } else {
          console.log(err);
        }
      }
    });
};
