export function wordRestrict(str) {
  if (!str) return "";

  let words = str.split(" ");
  let wordLimit = [];

  if (words.length > 256) {
    wordLimit = words.slice(0, 256);
    wordLimit.push("...");
  } else {
    wordLimit = words.slice(0, words.length);
  }
  return wordLimit.join(" ");
}
