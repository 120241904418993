import { useEffect } from "react";
import GoogleMap from "./GoogleMap";
import { scrollToTop } from "../Utils/ScrollToTop";

function TaroMap() {
  document.title = "Map - TARO";

  useEffect(() => {
    scrollToTop();
  }, [window.history.pathname]);

  return <GoogleMap />;
}

export default TaroMap;
