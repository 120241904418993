import React, { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./components/Header";
import Footer from "./components/Footer";
import FindingAid from "./components/FindingAid";
import SearchResultsView from "./components/SearchResultsView";
import Landing from "./components/Landing";
import About from "./components/About";
import TaroMap from "./components/TaroMap";
import RepositoryLandingPage from "./components/RepositoryLandingPage";
import Browse from "./components/Browse";
import Archives from "./components/Archives";
import Preview from "./components/Preview";
import ArchivesStats from "./components/Archives/ArchivesStats";
import NotFound from "./components/NotFound/index";
import SystemDown from "./components/NotFound/SystemDown";
import { Provider } from "react-redux";
import { scrollToTop } from "./components/Utils/ScrollToTop";
import { createBrowserHistory } from "history";
import store from "./store";
import "./scss/App.scss";
import Members from "./components/Members";
import Api from "./components/Api";
import Params from "./components/Api/Params";

function App() {
  let history = createBrowserHistory();
  // console.log('history', history.location.pathname)

  useEffect(() => {
    scrollToTop();
  }, [window.history.pathname]);

  return (
    <Provider store={store}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Texas Archival Resources Online</title>
        <meta
          name="description"
          content="Texas Archival Resources Online (TARO) is a consortial program that provides free public access to the collection descriptions or 'finding aids' created by the state’s archives, libraries, and museums to describe the primary source documents in their care."
        />
      </Helmet>
      <Header />
      <Switch>
        <Route path="/home" component={Landing} />
        {/* TODO: currently the query populates in the url before query actually runs */}
        <Route path="/search/:query" component={SearchResultsView} />
        <Route path="/about" component={About} />
        <Route exact path="/archives" component={Archives} />
        <Route path="/archives/stats" component={ArchivesStats} />
        <Route path="/map" component={TaroMap} />
        <Route
          path="/:repository/finding_aids/:findingAid"
          component={FindingAid}
        />
        <Route
          path="/repositories/:repository"
          component={RepositoryLandingPage}
        />
        <Route path="/browse/:category" component={Browse} />
        <Route path="/preview" component={Preview} />
        <Route path="/members" component={Members} />
        <Route exact path="/search-api" component={Api} />
        <Route path="/search-api/params" component={Params} />
        <Route path="/system_down" component={SystemDown} />
        <Route path="/not_found" component={NotFound} />

        <Route exact path="/">
          <Redirect to="/home" />
        </Route>

        <Route exact path="/search">
          <Redirect to="/search/:query" />
        </Route>

        <Route path="*">
          <Redirect to="/not_found" />
        </Route>
      </Switch>
      <Footer />
    </Provider>
  );
}

export default App;
