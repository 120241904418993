import React, { useState, useEffect } from "react";

function ResultsHeader(props) {
  const [results, setResults] = useState(props.perPage * props.pageNumber);

  useEffect(() => {
    props.loading(true);
    setResults(props.perPage * props.pageNumber);
    props.loading(false);
  }, [props.perPage, props.pageNumber]);

  return (
    <div className="card">
      <div className="card-body">
        <div className="row justify-content-center">
          <div className="col-lg-2 col-xs-12 card">
            <div className="card-body d-flex flex-column justify-content-center align-items-center py-3 m-auto">
              <div className="input-group ">
                <label htmlFor="inputGroupSelect01">Display Results</label>
              </div>
              <select
                className="custom-select page-size__select"
                id="inputGroupSelect01"
                onChange={(e) => {
                  props.setPerPage(Number(e.target.value));
                }}
              >
                <option
                  selected={props.perPage == 25 ? true : false}
                  value={25}
                >
                  25
                </option>
                <option
                  selected={props.perPage == 50 ? true : false}
                  value={50}
                >
                  50
                </option>
                <option
                  selected={props.perPage == 100 ? true : false}
                  value={100}
                >
                  100
                </option>
              </select>
            </div>
          </div>
          <div className="col-lg-8 col-xs-12 card">
            <div
              className={`d-flex result-summary ${
                props.terms ? "with" : "without"
              }`}
            >
              <div className="card-body d-flex align-items-center">
                <p className="card-title m-auto results-header">
                  Results Summary ( {props.display ? results + 1 : 0} -{" "}
                  {results + props.display} of {props.total} Total )
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultsHeader;
