import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { searchCreators } from "../../actions/searchCreators";
import { searchAllowedList } from "../../actions/searchAllowedList";
import { withRouter } from "react-router-dom";
import CreatorList from "./listCreators";
import AllowedList from "./listAllowed";
import Email from "../../images/envelope.svg";
import Loading from "../Utils/Loading";
import { scrollToTop } from "../Utils/ScrollToTop";

function Browse(props) {
  // console.log("browse", props);
  const selectedCategory = props.match.params.category;
  const [browse, setBrowse] = useState("");

  const switchBrowseBy = (param) => {
    // console.log("param ?", param);
    let query = "";
    switch (param) {
      case "creator":
        // console.log("creator");
        document.title = "Browse By Creator - TARO";
        props.searchCreators(query);
        return setBrowse(
          <div>
            <div className="card">
              <h1 className="card-title text-center">Browse By Creator</h1>
            </div>
            <CreatorList />
          </div>
        );
      case "geographic-area":
        // console.log("geo");
        document.title = "Browse By Geographic Area - TARO";
        query = "label=geographic+areas";
        props.searchAllowedList(query);
        return setBrowse(
          <div>
            <div className="card">
              <h1 className="card-title text-center">
                Browse By Geographic Area
              </h1>
            </div>
            <AllowedList />
          </div>
        );
      case "repositories":
        // console.log("repo");
        document.title = "Browse By Repository - TARO";
        return setBrowse(
          <div>
            <div className="card">
              <h1 className="card-title text-center">Browse By Repository</h1>
            </div>
            <AllowedList />
          </div>
        );
      case "subject":
        // console.log("subj");
        document.title = "Browse By Subject - TARO";
        query = "label=subject";
        props.searchAllowedList(query);
        return setBrowse(
          <div>
            <div className="card">
              <h1 className="card-title text-center">Browse By Subject</h1>
            </div>
            <AllowedList />
          </div>
        );
    };
  };

  useEffect(() => {
    // console.log(selectedCategory);
    switchBrowseBy(selectedCategory);
    scrollToTop(); 
  }, [window.location.pathname]);

  return (
    <div>
      {browse}
      {/* <div className="text-center card-body pb-4 pt-1">
        <p className="lead card-text">
          <a
            href={`mailto:lib-taro@email.com`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Us&nbsp;
            <img src={Email} className="icon" alt="email icon" />
          </a>
        </p>
      </div> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  repositoryData: state.repository.repositoryData,
});

export default withRouter(
  connect(mapStateToProps, { searchCreators, searchAllowedList })(Browse)
);
