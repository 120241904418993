import React from "react";

function ServerError() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light dark-bg flex-column">
        <h3 className="banner">
          The TARO Server is currently offline due to maintenance or another
          site issue.
        </h3>
        <p className="banner">
          Full functionality for the site will be restored as soon as possible.
          Thank you for your patience.
        </p>
      </nav>
    </div>
  );
}
export default ServerError;
