import { useEffect } from "react";
import { Link } from "react-router-dom";
import EndpointExample from "../EndpointExample.js";
import ApiTitle from "../ApiTitle.js";

function Params() {
  document.title = "TARO - API Parameters";

  const textParameter = {
    description: null,
    parameter: "text=<your text search>",
    link: "https://www.txarchives.org/api/finding_aid/search/?text=zoology",
    returns:
      'returns all finding aids that have "zoology" anywherein the content of the finding aid.',
  };
  const titleParameter = {
    description: null,
    parameter: "title=<your title search>",
    link: "https://www.txarchives.org/api/finding_aid/search/?title=UT Department of Zoology Records",
    returns:
      'returns all finding aids that have "UT Department of Zoology Records" in the title.',
  };
  const abstractParameter = {
    description: null,
    parameter: "abstract=<your abstract search>",
    link: "https://www.txarchives.org/api/finding_aid/search/?abstract=folklore",
    returns:
      'returns all finding aids that contain "folklore" AND "Austin" in the abstract.',
  };
  const digitalParameter = {
    description:
      "This is a boolean field: the provided value must be true or false.",
    parameter: "digital=true",
    link: "https://www.txarchives.org/api/finding_aid/search/?abstract=folklore",
    returns:
      'returns all finding aids that have "architecture" anywhere in the content of the finding aid AND are considered digital objects.',
  };
  const idParameter = {
    description:
      "This is a unique TARO identifier. This will only return a single finding aid. (This identifier is provided in the original finding aid XML file.)",
    parameter: "taro_identifier=<your TARO identifier>",
    link: "https://www.txarchives.org/api/finding_aid/search/?taro_identifier=urn:taro:utexas.cah.04948",
    returns:
      "returns a single finding aid that matches the provided taro identifier (urn:taro:utexas.cah.04948), if a match exists.",
  };
  const repoParameter = {
    description: `Search for finding aids that belong to a specific repository based on that repository's unique TARO abbreviation. (To view a repository's abbreviation, visit the About page and click on a repository. That repository's abbreviation will be in the URL.)`,
    parameter: "repository=<your repository search>",
    link: "https://www.txarchives.org/api/finding_aid/search/?repo=aalgs",
    returns:
      "returns finding aids that belong to the African American Library At The Gregory School.",
  };
  const languageParameter = {
    description: null,
    parameter: "languages=<your language search>",
    link: "https://www.txarchives.org/api/finding_aid/search/?languages=Japanese",
    returns: "returns finding aids associated with the 'Japanese' language.",
  };
  const creatorParameter = {
    description: null,
    parameter: "creators=<your creator search>",
    link: "https://www.txarchives.org/api/finding_aid/search/?creators=McNiel, Maura",
    returns:
      "returns finding aids associated with the creator 'McNiel, Maura'.",
  };
  const startDateParameter = {
    description:
      "Search for finding aids associated with the provided start date. Must be provided in (YYYY) format.",
    parameter: "start_dates=<your start year search>",
    link: "https://www.txarchives.org/api/finding_aid/search/?start_dates=1986",
    returns: "returns finding aids associated with a start date of '1986'.",
  };
  const endDateParameter = {
    description:
      "Search for finding aids associated with the provided end date. Must be provided in (YYYY) format.",
    parameter: "end_dates=<your end year search>",
    link: "https://www.txarchives.org/api/finding_aid/search/?end_dates=1986",
    returns: "returns finding aids associated with an end date of '1986'.",
  };
  const geographicParameter = {
    description: null,
    parameter: "geographic_areas=<your geographic area search>",
    link: "https://www.txarchives.org/api/finding_aid/search/?geographic_areas=Austin Texas",
    returns:
      "returns finding aids associated with the geographic area 'Austin Texas'.",
  };
  const topicParameter = {
    description: null,
    parameter: "subject_topics=<your topic search>",
    link: "https://www.txarchives.org/api/finding_aid/search/?subject_topics=jazz",
    returns: "returns finding aids associated with the subject topic 'jazz'.",
  };
  const personParameter = {
    description: null,
    parameter: "subject_persons=<your person search>",
    link: "https://www.txarchives.org/api/finding_aid/search/?subject_persons=Bailey, Linda",
    returns:
      "returns finding aids associated with the subject person 'Bailey, Linda'.",
  };
  const organizationParameter = {
    description: null,
    parameter: "subject_organizations=<your organization search>",
    link: "https://www.txarchives.org/api/finding_aid/search/?subject_organizations=University of Texas at Austin. College of Liberal Arts",
    returns:
      "returns finding aids associated with the subject organization 'University of Texas at Austin. College of Liberal Arts'.",
  };

  return (
    <div>
      <div className="blue-grey">
        <div className="container">
          <ApiTitle />
          <hr />
          <div className="container">
            <div className="card-body pb-4 pt-1">
              <center>
                <h3>Search Fields (Query Parameters)</h3>
                <p>
                  <em>
                    For more information about using the API, including advanced
                    searching options, check out{" "}
                    <Link to={`/search-api`}>Using the API</Link>
                  </em>
                </p>
              </center>
              <br></br>
              <h5 class="card-title">Full text search</h5>
              {EndpointExample(textParameter)}
            </div>
            <hr />
            <div className="card-body pb-4 pt-1">
              <h5 className="card-title">Single-valued fields</h5>
              <p className="card-text">
                Singular parameters. A given finding aid can only have a single
                value associated with these parameters; e.g., there is only one
                primary Title associated with each finding aid.
              </p>
              {EndpointExample(titleParameter)}
              <hr />
              {EndpointExample(abstractParameter)}
              <hr />
              {EndpointExample(digitalParameter)}
              <hr />
              {EndpointExample(idParameter)}
              <hr />
              {EndpointExample(repoParameter)}
            </div>
            <hr />
            <div className="card-body pb-4 pt-1">
              <h5 className="card-title">Multi-valued fields</h5>
              <p className="card-text">
                Plural parameters. A given finding aid can have multiple values
                associated with these parameters; e.g., multiple creators could
                be associated with the same finding aid.
              </p>
              {EndpointExample(languageParameter)}
              {EndpointExample(creatorParameter)}
              {EndpointExample(startDateParameter)}
              {EndpointExample(endDateParameter)}
              {EndpointExample(geographicParameter)}
              {EndpointExample(topicParameter)}
              {EndpointExample(personParameter)}
              {EndpointExample(organizationParameter)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Params;
