import { useEffect } from "react";
import { Link } from "react-router-dom";
import { scrollToTop } from "../Utils/ScrollToTop.js";

function Members() {
  document.title = "TARO - Members";

  useEffect(() => {
    scrollToTop();
  }, [window.history.pathname]);

  return (
    <div>
      <div className="blue-grey">
        <div className="container">
          <center>
            <br />
            <h1 className="card-title">TARO Members</h1>
          </center>
          <p className="card-body my-0 lead text-center">
            Contributing members of TARO (Texas Archival Resources Online) may
            find the following links helpful. If you represent an institution or
            organization that would like to join and contribute to TARO, please{" "}
            <a
              href="http://texastaro.pbworks.com/w/page/73447661/TARO%20wiki%20home"
              target="_blank"
            >
              contact the current TARO Committee.
            </a>
          </p>
        </div>
        <div className="text-center card-body pb-4 pt-1">
          <p className="card-text">
            <strong>
              <Link to={`/admin`}>TARO Administrative Portal Login</Link>
            </strong>
            <br />
            <strong>
              <Link to={`/preview`}>TARO Finding Aid Preview</Link>
            </strong>
          </p>
        </div>
        <div className="text-center card-body pb-4 pt-1">
          <h3>TARO Member Documentation</h3>
          <p className="card-text">
            <strong>
              <a
                href="https://utexas.box.com/s/5qy3zh1p36tdbovr3545q21gajepjfhg"
                target="_blank"
              >
                Management API
              </a>
              <br />
              <a
                href="https://utexas.box.com/s/rlcsaln5nksqp5srrwag6zhikc65824j"
                target="_blank"
              >
                Search API
              </a>
              <br />
              <a
                href="https://utexas.box.com/s/idvxh2emxyuqcv5g0aoo1i1fl9ltr3g3"
                target="_blank"
              >
                Finding Aids
              </a>
              <br />
              <a
                href="https://utexas.box.com/s/y9iftik0b6ejly7996t340l57kkzzguf"
                target="_blank"
              >
                Repositories
              </a>
              <br />
              <a
                href="https://utexas.box.com/s/fzgg7gobs9l664j63v1x5xvbbmmsctg8"
                target="_blank"
              >
                Login and Two-Factor Authentication
              </a>
              <br />
              <a
                href="https://utexas.box.com/s/yjhq8tggyl22hqggcyzmpc228nzjnkbm"
                target="_blank"
              >
                User Management
              </a>
              <br />
              <a
                href="https://utexas.box.com/s/s2pqbzf58zgrbpmil9jlw4yuhj35nd9l"
                target="_blank"
              >
                Administrative Site Theme
              </a>
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Members;
