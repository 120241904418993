import React from "react";
import Facets from "./Facets";

//instructions hidden currently for screen readers on how to navigate facet options. 
function Faceting(props) {
  const { repositoryData } = props;
  return (
    <div className="card card-body facets">
      <h2>Limit your search</h2>
      <div className="facet-instructions" style={{display:"none"}}>
      Instructions for keyboard navigate only. 
        <p>Use Tab to move to the next item.</p>
        <p>To go back up to the previous item, use Tab+Shift</p>
        <p>To select an item, use Enter</p>
        <p>To remove selected item, use Backspace</p>
      </div>
      <Facets repositoryData={repositoryData} />
    </div>
  );
}

export default Faceting;
