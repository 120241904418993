import React from "react";
import Form from "./Form";
import { connect } from "react-redux";
import { searchFindingAids } from "../../../actions/searchFindingAids";

function AdvancedSearch(props) {
  const { repositoryData } = props;

  return (
    <div>
      <center>
        <h2>Advanced</h2>
      </center>
      <Form repositoriesList={repositoryData} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  repositoryData: state.repository.repositoryData,
});

export default connect(mapStateToProps, { searchFindingAids })(AdvancedSearch);
