// Setup config with token - helper function
export const tokenConfig = (getState) => {
  const token = process.env.REACT_APP_API_TOKEN;
  const env = process.env.REACT_APP_ENV;
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // If token, add to headers config
  if (token && env !== "LOCAL") {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};
