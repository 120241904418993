import React from "react";

function Banner() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light dark-bg flex-column">
        <h3 className="banner">TARO is currently undergoing maintenance.</h3>
        <p className="banner">
          Site performance and functionality may be impacted. Finding Aids may
          not be available to find and/or view.
        </p>
      </nav>
    </div>
  );
}
export default Banner;
