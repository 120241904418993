import React from "react";
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Email from "../../../images/envelope.svg";

export default function AccessMaterials(props) {
  //need to get the access_link : https://github.austin.utexas.edu/glib/taro-2/blob/develop/taro/taro_manager/models.py#L44
  //Do we want to shorten the title? Is there a better field to use here?
  const { access, title, contact } = props.materials;
  let url = new URL(window.location.href);
  return (
    <div className="card">
      <AccordionItemHeading className="card-header">
        <AccordionItemButton>
          <div className="card-title lead">Access Materials</div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="card-body">
        {(access !== null || title !== null) && <a href={access}>{title}</a>}
        <br></br>
        <br></br>
        <a
          href={`mailto:${contact}?subject=TARO Finding Aid Inquiry - ${url.pathname}&Body=Inquiry about ${url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact This Repository About this Finding Aid&nbsp;
          <img src={Email} className="icon" alt="email icon" />
        </a>
      </AccordionItemPanel>
    </div>
  );
}
