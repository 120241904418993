import React from "react";
import OtherFormats from "./OtherFormats/index";
import AccessMaterials from "./AccessMaterials";
import Citations from "./Citations/CitationButton";
import TableOfContents from "./TableOfContents/TableOfContents";
import { Accordion, AccordionItem } from "react-accessible-accordion";

function FindingAidSideBar(props) {
  return (
    <div className="sticky-top">
      <Accordion
        allowMultipleExpanded={true}
        allowZeroExpanded={true}
        preExpanded={["other-formats", "access-materials", "table-of-contents"]}
      >
        <AccordionItem uuid="other-formats">
          <OtherFormats
            printLink={props.printLink}
            toggled={props.toggled}
            setToggled={props.setToggled}
          />
        </AccordionItem>
        <AccordionItem uuid="access-materials">
          <AccessMaterials materials={props} />
        </AccordionItem>
        <AccordionItem uuid="table-of-contents">
          <TableOfContents info={props} setToggled={props.setToggled} />
        </AccordionItem>
        <Citations cite={props} />
      </Accordion>
    </div>
  );
}

export default FindingAidSideBar;
