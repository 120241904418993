import React, { useState } from "react";
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Xicon from "../../../../images/x-lg.svg";

function TableOfContents(props) {
  const { passed } = props.info;
  const [seriesToggle, setSeriesToggle] = useState(false);
  let inventory_details = "";
  // console.log("passed? ", passed);

  let output = [];
  let output1 = [];
  let output2 = [];

  passed.forEach((item, id) => {
    //each section included in the finding aid, creating anchor link
    // console.log(`anchor item ${item}`)
    output1.push(
      <dt key={id}>
        <a
          onClick={() => {
            props.setToggled(false);
          }}
          href={`#${item.anchor}`}
        >
          {item.name}
        </a>
      </dt>
    );
    // if we have an inventory, we need to include a view series button with details.
    if (item.name === "Inventory") {
      // console.log("items }} ", item.data);
      inventory_details = [];
      item.data.forEach((c0) => {
        // console.log(Object.keys(c0));
        if (Object.keys(c0)[0] !== "inventory_header") {
          inventory_details.push(c0);
        }
      });

      inventory_details.forEach((subitem, index) => {
        // console.log("subitem", Object.keys(subitem));
        let series = index + 1;
        if (Object.keys(subitem)[0] === "c01") {
          let subcoltitle = { ...subitem.c01.container_summary };
          let subcolstring = ""
          if (subcoltitle["Title:"] !== undefined) {
            subcolstring = String(subcoltitle["Title:"].join(""))
            if (subcolstring.includes("</")) {
              let remove_html = subcolstring.replace(/<([^\d\s])([^>]+){0,1}>/gi, "");
              subcolstring = remove_html
            }
          }

          // console.log("index?", series, "details", subcolstring);
          output2.push(
            <li key={index}>
              <a
                href={"##series" + series}
                onClick={() => {
                  setSeriesToggle(!seriesToggle);
                }}
              >
                {subcolstring.length > 0 ? subcolstring : `Item $ {index + 1}`}
              </a>
            </li>
          );
        }
      });
    }
  });

  output.push(output1);
  // console.log("output 2", output2);

  return (
    <div>
      <div className="card">
        <AccordionItemHeading className="card-header">
          <AccordionItemButton>
            <div className="card-title lead">Table of Contents</div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="card-body">
          {output.length > 0 && (
            <div>
              <dl>{output}</dl>
              {/* validate there is an inventory for view series details */}
              {output2.length > 0 && (
                <button
                  className="btn btn-primary dark-bg"
                  onClick={() => setSeriesToggle(!seriesToggle)}
                >
                  View Inventory
                </button>
              )}
            </div>
          )}
        </AccordionItemPanel>
      </div>
      {output2.length > 0 && (
        <div
          id="subcol-popup"
          className={`p-5 ${seriesToggle ? "" : "hidden"}`}
        >
          <a className="close">
            <img
              src={Xicon}
              alt="X to close modal"
              onClick={() => setSeriesToggle(!seriesToggle)}
            />
          </a>
          <ul>{output2}</ul>
        </div>
      )}
    </div>
  );
}

export default TableOfContents;
