import React from "react";

function EndpointExample(props) {
  return (
    <>
      <p className="card-text">
        <strong>{props.parameter ? props.parameter : null}</strong>
      </p>
      <p className="card-text">
        {props.description ? props.description : null}
      </p>
      <p className="card-text border border-primary api-example">
        <strong>Example:</strong>{" "}
        <a href={props.link} target="_blank" className="link">
          {props.link}
        </a>
        <br></br>
        <br></br>
        {"→"} {props.returns}
      </p>
    </>
  );
}

export default EndpointExample;
