import { useEffect } from "react";
import RepositoryList from "./listRepositories.js";
import { Link } from "react-router-dom";
import Email from "../../images/envelope.svg";
import { scrollToTop } from "../Utils/ScrollToTop.js";

function About() {
  document.title = "TARO - About";

  useEffect(() => {
    scrollToTop();
  }, [window.history.pathname]);

  return (
    <div>
      <div className="blue-grey">
        <div className="container">
          <center>
            <br />
            <h1 className="card-title">About TARO</h1>
          </center>
          <p className="card-body my-0 lead text-center">
            TARO (Texas Archival Resources Online) makes descriptions of the
            rich archival, manuscript, and museum collections in repositories
            across the state available to the public. The site consists of the
            collection descriptions or "finding aids" that archives, libraries,
            and museums create to assist users in locating information in their
            collections. Consider these an extended table of contents which
            describe unique materials only available at the individual
            repositories.{" "}
            <em>
              In most cases, the collections themselves are NOT available
              online.
            </em>
          </p>
          <div className="text-center card-body pb-4 pt-1">
            <p className="card-text">
              <strong>
                <Link to={`/archives`}>Phase One Archives</Link>
              </strong>
              <br />
              <strong>
                <Link to={`/archives/stats`}>Phase One Statistics</Link>
              </strong>
            </p>
          </div>
          <div className="text-center card-body pb-4 pt-1">
            <p className="card-text">
              <em>
                For repository or institutional specific contact information,
                please select one of the repositories below.
              </em>
              {/* <a
                href={`mailto:lib-taro@email.com`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us&nbsp;
                <img src={Email} className="icon" alt="email icon" />
              </a> */}
            </p>
          </div>
        </div>
      </div>
      <div>
        <RepositoryList />
      </div>
      <div className="blue-grey">
        <p className="container pb-5">
          The finding aids on the TARO site do not represent descriptions of the
          entire holdings of any of the repositories. For more information or to
          find out how to contact the staff, follow the links to each
          institution above.{" "}
          <a
            href="http://texastaro.pbworks.com/w/page/73447661/TARO%20wiki%20home"
            target="_blank"
          >
            Link to Staff Page
          </a>
        </p>
      </div>
    </div>
  );
}

export default About;
