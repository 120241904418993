import axios from "axios";
import { FACET_FINDINGAIDS } from "./types";
import { tokenConfig } from "./auth";

// SEARCH FINDING AID
export const facetFindingAids = (query) => (dispatch, getState) => {
  axios
    .get(
      `${process.env.REACT_APP_API_BASE}/api/finding_aid/search/facets/?${query}`,
      tokenConfig(getState)
    )
    .then((response) => {
      if (response.status === 200 && response.data.length === 0) {
        window.location.href = "/not_found"; 
      } else {
      dispatch({
        type: FACET_FINDINGAIDS,
        payload: {
          ...response.data.fields,
          query: query,
        },
      });
    }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 404) {
          window.location.href = "/not_found";
        } else {
          console.log(err);
        }
      }
    });
};
