import React from "react";
import { Link } from "react-router-dom";

function NavDropdown() {
  const digitalToolTip =
    "Search finding aids with links to digitized collection material.";

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light dark-bg">
        <div className="container-fluid">
          <button
            className="navbar-toggler bg-light"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mr-xs-auto">
              <li className="nav-item">
                <Link to={`/home`} className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Browse By
                </a>
                <div
                  className="dropdown-menu dark-bg"
                  alt="browseby-dropdown-menu"
                >
                  <Link to={`/browse/creator`} className="dropdown-item">
                    Creator
                  </Link>
                  <Link
                    to={`/browse/geographic-area`}
                    className="dropdown-item"
                  >
                    Geographic Area
                  </Link>
                  <Link to={`/browse/subject`} className="dropdown-item">
                    Subject
                  </Link>
                  <Link to={`/browse/repositories`} className="dropdown-item">
                    Repository
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  API
                </a>
                <div
                  className="dropdown-menu dark-bg"
                  alt="browseby-dropdown-menu"
                >
                  <Link to={`/search-api`} className="dropdown-item">
                    Using the API
                  </Link>
                  <Link to={`/search-api/params`} className="dropdown-item">
                    Query Parameters
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <Link to={`/map`} className="nav-link">
                  Map
                </Link>
              </li>
              <li className="nav-item">
                <Link to={`/about`} className="nav-link">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link to={`/members`} className="nav-link">
                  Members
                </Link>
              </li>
              <li className="nav-item">
                <Link to={`/search/:query?`} className="nav-link">
                  Search
                </Link>
              </li>
              <li className="nav-item"></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
export default NavDropdown;
