import { useEffect } from "react";
import { scrollToTop } from "../Utils/ScrollToTop.js";
import { useState } from "react";
import FindingAidPreview from "../FindingAid/FindingAidPreview.js";

function Preview() {
  document.title = "TARO - Preview";

  useEffect(() => {
    scrollToTop();
  }, [window.history.pathname]);

  const [files, setFiles] = useState("");
  const [isValidExtension, setValidExtension] = useState(true);

  const handleChange = (e) => {
    const fileReader = new FileReader();
    let extension = e.target.files[0].name.split(".").pop().toLowerCase();
    if (extension == "json") {
      setValidExtension(true);
      fileReader.readAsText(e.target.files[0], "UTF-8");
      fileReader.onload = (e) => {
        setFiles(JSON.parse(sanitize(e.target.result)));
      };
    } else {
      setValidExtension(false);
    }
  };

  const sanitize = (str) => {
    const map = {
      "&": "&",
      "<": "<",
      ">": ">",
      '"': '"',
      "'": "'",
    };

    const reg = /[&<>"']/gi;

    return str.replace(reg, (match) => {
      return map[match];
    });
  };

  return (
    <>
      <div>
        <div className="blue-grey">
          <div className="container">
            <center>
              <br />
              <h1 className="card-title">Finding Aid Preview</h1>
              <p>
                Upload a generated Finding Aid JSON file to preview the display.
              </p>
              <p>
                <em>
                  JSON used for display must be generated via the TARO Member
                  Administrative Portal.
                </em>
              </p>
              <center>
                <input type="file" onChange={handleChange} />
              </center>
              <br />
            </center>
          </div>
          {isValidExtension ? (
            <div className="text-center card-body pb-4 pt-1">
              <div className="fa-preview">
                <FindingAidPreview props={files} />
              </div>
            </div>
          ) : (
            <div className="text-center card-body pb-4 pt-1">
              <p>
                <strong>
                  Invalid file type uploaded. Only json files are accepted.
                </strong>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Preview;
