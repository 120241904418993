import React from "react";

function SystemDown() {
  document.title = "TARO Maintenance";

  return (
    <div className="container-fluid">
      <h1>500</h1>
      <h3>Server Down</h3>
      <p>Our system is currently down. Please try back again later.</p>
    </div>
  );
}

export default SystemDown;
