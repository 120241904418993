import { useEffect } from "react";
import { connect } from "react-redux";
import { searchRepositories } from "../../actions/searchRepositories";
import { withRouter } from "react-router-dom";

// Fetch all repositories and sets the result to state through Redux.
function GetRepositories(props) {
  useEffect(() => {
    const query = ``;
    props.searchRepositories(query);
  }, [props]);
  return null;
}

export default withRouter(
  connect(null, { searchRepositories })(GetRepositories)
);
