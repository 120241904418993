import { useEffect } from "react";
import { Link } from "react-router-dom";
import EndpointExample from "./EndpointExample.js";
import ApiTitle from "./ApiTitle.js";

function Api() {
  document.title = "TARO - Search API";

  const basicSearch = {
    description: null,
    parameter: null,
    link: "https://www.txarchives.org/api/finding_aid/search/?repository=utaaa&text=Kermacy",
    returns:
      'returns all finding aids that belong to the repository "utaaa" (The University Of Texas At Austin Alexander Architectural Archive) AND contain the text "Kermacy" anywhere in the content of the finding aid.',
  };
  const exactSearch = {
    description: null,
    parameter: null,
    link: 'https://www.txarchives.org/api/finding_aid/search/?title="UT Department of Zoology Records, 1892-2001"',
    returns:
      "returns all finding aids with an exact match to the provided title.",
  };
  const listOneSearch = {
    description: null,
    parameter: null,
    link: "https://www.txarchives.org/api/finding_aid/search/?abstract=[folklore,Austin]",
    returns:
      'returns all finding aids that contain "folklore" AND "Austin" in the abstract.',
  };
  const listTwoSearch = {
    description: null,
    parameter: null,
    link: 'https://www.txarchives.org/api/finding_aid/search/?abstract=["folklore","Austin"]',
    returns:
      'returns all finding aids that contain "folklore" OR "Austin" in the abstract.',
  };
  const listThreeSearch = {
    description: null,
    parameter: null,
    link: "https://www.txarchives.org/api/finding_aid/search/?creators=[Smith,Jones]",
    returns:
      'returns all finding aids that have both "Smith" AND "Jones" as associated creators.',
  };
  const listFourSearch = {
    description: null,
    parameter: null,
    link: 'https://www.txarchives.org/api/finding_aid/search/?creators=["Smith","Jones"]',
    returns:
      'returns all finding aids that have both "Smith" OR "Jones" as associated creators.',
  };

  return (
    <div>
      <div className="blue-grey">
        <div className="container">
          <ApiTitle />
          <hr />
          <div className="container">
            <div className="card-body pb-4 pt-1">
              <center>
                <h3>Using the API</h3>
                <p>
                  <em>
                    For more information about available query parameters, check
                    out <Link to={`/search-api/params`}>API Parameters</Link>
                  </em>
                </p>
              </center>
              <br></br>
              <h5 class="card-title">Basic Searches</h5>
              <p className="card-text">
                The base endpoint is{" "}
                <strong>
                  https://www.txarchives.org/api/finding_aid/search/
                </strong>
                . Add a <strong>?</strong> to indicate the beginning of the
                query followed by search parameters. Separate search parameters
                with the <strong>&</strong> symbol. Results are returned in
                JSON.
              </p>
              {EndpointExample(basicSearch)}
            </div>
            <hr />
            <div className="card-body pb-4 pt-1">
              <h5 class="card-title">Exact Searches</h5>
              <p className="card-text">
                Exact searches are supported for all single and multi-valued
                fields except for digital. Enclose the search parameter in
                quotations (double or single).
              </p>
              {EndpointExample(exactSearch)}
            </div>
            <hr />
            <div className="card-body pb-4 pt-1">
              <h5 class="card-title">Using Lists</h5>
              <p className="card-text">
                All single and multi-valued parameters except for digital can
                accept a list of values enclosed by brackets. By default this is
                performed as an exclusive AND search. To perform an inclusive OR
                search, enclose each of the values inside the list with double
                quotations.{" "}
              </p>
              {EndpointExample(listOneSearch)}
              {EndpointExample(listTwoSearch)}
              {EndpointExample(listThreeSearch)}
              {EndpointExample(listFourSearch)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Api;
