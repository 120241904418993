import React, { useRef } from "react";
import FindingAidJSON from "./FindingAidJSON";
import FindingAidDisplay from "./FindingAidDisplay";

function FindingAid() {
  const componentRef = useRef();
  return (
    <div>
      <FindingAidJSON />
      <FindingAidDisplay />
    </div>
  );
}

export default FindingAid;
