import React from "react";
import { useState } from "react";
import TaroLogo from "./TaroLogo";
import NavDropdown from "./NavDropdown";
import SearchForm from "./SearchForm";
import GetRepositories from "./getRepositories";
import Banner from "./MaintenanceBanner";
import { connect } from "react-redux";
import Loading from "../Utils/Loading";
import ServerError from "../Header/ServerError";

function Header(props) {
  const [timerExpired, setTimerExpired] = useState(false);

  let maintenance =
    typeof process.env.REACT_APP_MAINTENANCE === "undefined" ||
    !process.env.REACT_APP_MAINTENANCE
      ? false
      : process.env.REACT_APP_MAINTENANCE;
  let results = props.repositoryData.length ? true : false;

  setTimeout(() => {
    setTimerExpired(true);
  }, 6500);

  return (
    <div>
      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="col-lg-5 px-3">
            <TaroLogo />
          </div>
          <div className="col px-3">
            <GetRepositories />
            <SearchForm />
          </div>
        </div>
      </div>
      {!results && <Loading />}
      {!results && timerExpired && <ServerError />}
      {maintenance === "true" && <Banner />}
      <NavDropdown />
    </div>
  );
}

const mapStateToProps = (state) => ({
  repositoryData: state.repository.repositoryData,
});

export default connect(mapStateToProps)(Header);
