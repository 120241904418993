import React, { useState, useEffect } from "react";

export default function SearchQueryInfo(props) {
  const { repositoriesList, toggle, toggleSummary } = props;
  const [searchList, setSearchList] = useState("");

  let deconstruct = window.location.pathname.split("/");

  let CurrentQuery = {
    creators: "",
    geographic_areas: "",
    text: "",
    repository: "",
    subject_topics: "",
    subject_persons: "",
    subject_organizations: "",
    title: "",
    digital: false,
    languages: [],
    end_dates: [],
    start_dates: [],
  };

  const multiEntry = (key) => {
    // console.log('key ?', key, '\n testing ?', deconstruct.includes(key))
    if (deconstruct[2].includes(key)) {
      let list = deconstruct[2].split("&");
      list.forEach((item) => {
        if (item.includes(key)) {
          let updated = item.split("=")[1];
          CurrentQuery[key].push(updated);
        }
      });
    }
  };

  //deconstruct current query for delay purposes
  useEffect(() => {
    // console.log("deconstruct : ", deconstruct);
    if (deconstruct[2] != ":query") {
      for (let i = 2; i < deconstruct.length; i++) {
        let pair = deconstruct[i].split(/[=&]/);
        Object.keys(CurrentQuery).forEach((key) => {
          if (
            key == "languages" ||
            key == "start_dates" ||
            key == "end_dates"
          ) {
            multiEntry(key);
          } else {
            if (pair.indexOf(key) > -1) {
              let value = pair.indexOf(key) + 1;
              if (key === "repository") {
                CurrentQuery["repository"] = pair[value];
                repositoriesList.forEach((repo) => {
                  if (repo.abbr_name.trim() === CurrentQuery["repository"]) {
                    return (CurrentQuery["repository"] = repo.name);
                  }
                });
              } else {
                let newText = pair[value];

                if (newText.includes("%")) {
                  newText = decodeURIComponent(pair[value]);
                }
                return (CurrentQuery[key] = newText);
              }
            }
          }
        });
        // console.log("current query", CurrentQuery);
      }

      let newString = [];

      Object.keys(CurrentQuery).forEach((key) => {
        if (CurrentQuery[key].length > 0) {
          // console.log('key', key, " : ", CurrentQuery[key])
          switch (key) {
            case "creators":
              newString.push(`Creator: ${CurrentQuery["creators"]}`);
              break;
            case "geographic_areas":
              newString.push(
                `Geographic Area: ${CurrentQuery["geographic_areas"]}`
              );
              break;
            case "text":
              newString.push(`General Search on: ${CurrentQuery["text"]}`);
              break;
            case "subject_topics":
              newString.push(`Subject Topic of: ${CurrentQuery["subject_topics"]}`);
              break;
            case "subject_persons":
              newString.push(`Subject Person of: ${CurrentQuery["subject_persons"]}`);
              break;
            case "subject_organizations":
              newString.push(`Subject Organization of: ${CurrentQuery["subject_organizations"]}`);
              break;
            case "title":
              newString.push(`Title : ${CurrentQuery["title"]} `);
              break;
            case "repository":
              newString.push(`Repository: ${CurrentQuery["repository"]}`);
              break;
            case "digital":
              if (CurrentQuery["digital"]) {
                newString.push("Restricted to digital content");
              }
              break;
            // there are be more than one language and more than one start/end dates
            case "languages":
              newString.push(`Languages: ${CurrentQuery["languages"]}`);
              break;
            case "end_dates":
              newString.push(`End Dates: ${CurrentQuery["end_dates"]}`);
              break;
            case "start_dates":
              newString.push(`Start Dates : ${CurrentQuery["start_dates"]}`);
              break;
          }

          return setSearchList(newString);
        }
      });
    }
  }, [window.location.pathname]);

  return (
    <div className="card">
      <div className="card-body">
        Search Summary
        <button
          className={`${
            toggle ? "btn btn-dark toggleSearch" : "btn btn-light toggleSearch"
          }`}
          onClick={() => toggleSummary(!toggle)}
        >
          {" "}
          {toggle ? (
            <p className="summary_arrow" name="collapse summary">
              {" "}
              ▲{" "}
            </p>
          ) : (
            <p className="summary_arrow" name="expand summary">
              {" "}
              ▼{" "}
            </p>
          )}
        </button>
        {toggle ? (
          <ul>
            {searchList.length > 0
              ? searchList.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })
              : null}
          </ul>
        ) : null}
      </div>
    </div>
  );
}
