import {
  SEARCH_FINDINGAIDS,
  SORT_FINDINGAIDS,
  RESET_FINDINGAIDS,
} from "../actions/types";

const initialState = {
  findingAids: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_FINDINGAIDS:
      return {
        findingAids: [...action.payload],
      };
    case SORT_FINDINGAIDS:
      return {
        findingAids: [...action.payload],
      };
    case RESET_FINDINGAIDS:
      return {
        findingAids: [...action.payload],
      };
    default:
      return state;
  }
}
