import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { sortFindingAids } from "../../actions/sortFindingAids";

function ResultsSort(props) {
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [orderButton, setOrderButton] = useState("");
  const [reset, setReset] = useState(false);
  const { findingAids, sortFindingAids } = props;
  const query = window.location.pathname;

  //need to reset the sort function when a new search occurs. Default should be shown
  // console.log("sort by :: ", props, " window", query);

  // if we change the sort option, we should default it to "up", update the sort info for the results page
  useEffect(() => {
    //update the status of loading while a sort is occurring
    props.loading(true);
    props.sortInfo(sort);
    props.loading(false); //once new results are returned, it should be considered loading complete
  }, [sort, findingAids]);

  //if there is a change to the order, we should change the order button and sort results
  useEffect(() => {
    sortOrderButtons();
  }, [order]);

  useEffect(() => {
    sortOrderButtons();
  }, [sort]);

  //if either the sort category or the order changes, then we should run sort function
  useEffect(() => {
    props.loading(true);
    sortFindingAids(sort, order);
    props.loading(false);
  }, [sort, order]);

  //change the order if click, from up to down or down to up
  const handleOrderClick = () => {
    if (order === "up") {
      setOrder("down");
    } else if (order === "down") {
      setOrder("up");
    }
  };

  const sortOrderButtons = () => {
    if (sort === "creators" || sort === "title" || sort === "repository") {
      if (order === "up") {
        return setOrderButton(
          <button
            className="btn btn-light ml-3 "
            aria-pressed="true"
            aria-label="sort A to Z"
            onClick={(e) => handleOrderClick(e)}
          >
            A &#x021C5; Z
          </button>
        );
      } else if (order === "down") {
        return setOrderButton(
          <button
            className="btn btn-light ml-3 "
            aria-pressed="true"
            aria-label="sort Z to A"
            onClick={(e) => handleOrderClick(e)}
          >
            Z &#x021C5; A
          </button>
        );
      }
    } else if (sort === "start_dates" || sort === "end_dates") {
      if (order === "up") {
        return setOrderButton(
          <button
            className="btn btn-light ml-3 "
            aria-pressed="true"
            aria-label="sort oldest to newest"
            onClick={(e) => handleOrderClick(e)}
          >
            0 &#x021C5; 9
          </button>
        );
      } else if (order === "down") {
        return setOrderButton(
          <button
            className="btn btn-light ml-3 "
            aria-pressed="true"
            aria-label="sort newest to oldest"
            onClick={(e) => handleOrderClick(e)}
          >
            9 &#x021C5; 0
          </button>
        );
      }
    } else {
      setOrder("");
      setSort("");
      setReset(true);
      setOrderButton(
        <button
          className="btn btn-outline-secondary ml-3 "
          aria-pressed="true"
          aria-label="sort A to Z"
          disabled
        >
          A &#x021C5; Z
        </button>
      );
      setReset(false);
    }
  };

  const options = [
    { value: "default", select: "Category" },
    { value: "creators", select: "Creator" },
    { value: "title", select: "Title" },
    { value: "repository", select: "Repository" },
    { value: "start_dates", select: "Start Dates" },
    { value: "end_dates", select: "End Dates" },
  ];

  const handleSelect = (text) => {
    // console.log("on change for drop down", text);
    if (reset) {
      setOrder("");
      setSort(text.value);
    } else {
      setOrder("up");
      setSort(text);
    }
  };

  useEffect(() => {
    props.loading(true);
    setReset(true);
    setSort("");
    setOrder("");
    setReset(false);
    props.loading(false);
  }, [query]);

  return (
    <div className="card">
      <div className="card-body">
        <div className="row mx-auto d-flex">
          <div className="col-12 mx-auto d-sm-flex justify-content-center align-items-center">
            <label
              htmlFor="sort_by"
              className="d-flex align-items-center justify-content-center text-center mb-0"
            >
              Sort By
            </label>
            <div className="m-3 align-items-center justify-content-center text-center">
              <select
                name="sort_by"
                className="custom-select page-sort__select"
                id="sort_by"
                defaultValue="default"
                value={reset ? options[0].select : sort}
                onChange={(e) => {
                  setReset(false);
                  handleSelect(e.target.value);
                }}
              >
                {options.map((entry, i) => {
                  return (
                    <option key={i} value={entry.value}>
                      {entry.select}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="d-flex align-items-center justify-content-center text-lg-start">
              {orderButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  findingAids: state.findingAids.findingAids,
});

export default connect(mapStateToProps, { sortFindingAids })(ResultsSort);