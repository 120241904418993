import React from "react";
import { connect } from "react-redux";
import { searchFindingAids } from "../../actions/searchFindingAids";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

function CollectionRestrictions(props) {
  return (
    <AccordionItem uuid="collection-restrictions" className="summary card">
      <AccordionItemHeading className="card-header">
        <AccordionItemButton>
          <a name={props.name}>
            <p
              className="card-title accordion-headers"
              id="collection_restrictions"
            >
              Collection Restrictions
            </p>
          </a>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="card-body">
        <div className="card-text">
          {Object.keys(props.list).map((item, i) => (
            <div key={i}>
              <p
                tabIndex="0"
                dangerouslySetInnerHTML={{
                  __html: item + " " + props.list[item],
                }}
              ></p>
            </div>
          ))}
        </div>
      </AccordionItemPanel>
    </AccordionItem>
  );
}

const mapStateToProps = (state) => ({
  findingAid: state.findingAids.findingAids,
});

export default connect(mapStateToProps, { searchFindingAids })(
  CollectionRestrictions
);
