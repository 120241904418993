import axios from "axios";
import { SEARCH_FINDINGAIDS } from "./types";
import { tokenConfig } from "./auth";

// SEARCH FINDING AIDS
// Note: this is used for browse, filter, and sort as well.
// Only the params in the query change.
export const searchFindingAids = (query) => (dispatch, getState) => {
  //unpacks the query for cases where no valid search results found
  // console.log("QUERY", query);

  let not_found_msg = "";
  let QueryDetails = {
    creators: "",
    geographic_areas: "",
    text: "",
    repository: "",
    subject_topics: "",
    subject_persons: "",
    subject_organizations: "",
    title: "",
  };

  let queryArr = query.split("&");

  for (var index in QueryDetails) {
    queryArr.forEach((item) => {
      let set = item.split("=");
      if (set[0] === index) {
        QueryDetails[index] = set[1];
      }
    });

    if (QueryDetails[index] !== "") {
      if (not_found_msg.length > 0) {
        not_found_msg += ", ";
      }
      if (index === "repository") {
        not_found_msg += "\n that repository";
      } else if (index === "subject_topics") {
        not_found_msg += `\n the subject (topic) of ${QueryDetails[index]}`;
      } else if (index === "subject_persons") {
        not_found_msg += `\n the subject (person) of ${QueryDetails[index]}`;
      } else if (index === "subject_organizations") {
        not_found_msg += `\n the subject (organization) of ${QueryDetails[index]}`;
      } else if (index === "geographic_areas") {
        not_found_msg += `\n the geographic area of ${QueryDetails[index]}`;
      } else {
        not_found_msg += `\n ${index} : ${QueryDetails[index]}`;
      }
    }
  }

  if (query === ":query" || query === "") {
    dispatch({
      type: SEARCH_FINDINGAIDS,
      payload: [],
    });
  } else {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE}/api/finding_aid/search/?${query}`,
        tokenConfig(getState)
      )
      .then((response) => {
        // console.log("action", response.data);
        if (response.status === 200 && response.data.length === 0) {
          dispatch({
            type: SEARCH_FINDINGAIDS,
            payload: [
              "found nothing",
              `Sorry, we did not find information on ${not_found_msg}`,
            ],
          });
        } else if (response.status === 200 && query === "sort_dsc=date_added") {
          dispatch({
            type: SEARCH_FINDINGAIDS,
            payload: response.data.slice(0, 15),
          });
        } else {
          dispatch({
            type: SEARCH_FINDINGAIDS,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        // console.log("err");
        if (err.response) {
          if (err.response.status === 404) {
            window.location.href = "/not_found";
          } else if (err.response.status === 500) {
            window.location.href = "/system_down";
          } else {
            console.log(err);
          }
        } else {
          console.log(err);
        }
      });
  }
};
