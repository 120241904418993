import { SORT_FINDINGAIDS } from "./types";

export const sortFindingAids = (type, order) => async (dispatch, getState) => {
  //use merge sort, since we may have very large lists we are working with
  //when using getState, we should preserve the state of the last sort when we switch to a new type.
  let { findingAids } = getState().findingAids;

  //this way it should pull the name, but the abbr. for sorting
  if (type === "repository") {
    type = "repository_name";
  }

  const quickSort = async (arr, start, end) => {
    if (arr.length === 1) {
      return arr;
    }

    if (start >= end) {
      return;
    }

    let index = await partition(arr, start, end);

    await Promise.all([
      quickSort(arr, start, index - 1),
      quickSort(arr, index + 1, end),
    ]);

    return arr;
  };

  //determine what need to be switched.
  const partition = async (arr, start, end) => {
    let pivotValue = arr[end];
    let pivotIndex = start;

    const swapUp = async (left, right, index) => {
      // console.log('swapping left and right', left, right)

      if (left < right) {
        await swap(arr, index, pivotIndex);
        pivotIndex++;
      }
    };

    const swapDown = async (left, right, index) => {
      if (left > right) {
        await swap(arr, index, pivotIndex);
        pivotIndex++;
      }
    };

    //functions related to dates
    const sortDates = (arr) => {
      let dates = arr.sort((a, b) => parseInt(a) - parseInt(b));

      let number_dates = dates.map((n) => parseInt(n));
      // console.log('details', dates, number_dates)
      if (type === "end_dates") {
        return number_dates.reverse();
      } else {
        return number_dates;
      }
    };

    const validateDates = (value) => {
      let result = 0;
      // console.log('valid date >> ', value)
      if (typeof value === "object") {
        result = value[0];
      } else if (value === -1) {
        result = value
      }
      return result;
    };

    for (let i = start; i < end; i++) {
      let checkPivotValue = pivotValue[type];
      let checkValue = arr[i][type];
      // console.log("pivot", pivotValue, "\n check", checkPivotValue, "\n value", checkValue);
      if (type === "title" || type === "repository_name") {
        if (order === "up") {
          await swapUp(
            checkValue.toLowerCase(),
            checkPivotValue.toLowerCase(),
            i
          );
        } else if (order === "down") {
          await swapDown(
            checkValue.toLowerCase(),
            checkPivotValue.toLowerCase(),
            i
          );
        }
      }

      if (type === "creators") {
        //order the list of creators
        // console.log(typeof checkPivotValue, typeof checkValue);

        if (checkPivotValue.length > 1) {
          checkPivotValue.sort((a, b) => a.localeCompare(b));
        }

        if (checkValue === undefined) {
          checkValue = [""];
        } else if (typeof checkValue === "object") {
          if (checkValue.length > 1) {
            checkValue.sort((a, b) =>
              a.toLowerCase().localeCompare(b.toLowerCase())
            );
          } else {
            checkValue = [checkValue];
          }
        } else {
          checkValue = [checkValue];
        }

        checkPivotValue =
          typeof checkPivotValue[0] === "string"
            ? checkPivotValue[0].toLowerCase()
            : checkPivotValue[0].toString().toLowerCase();
        checkValue =
          checkValue[0] === "string"
            ? checkValue[0].toLowerCase()
            : checkValue[0].toString().toLowerCase();

        // console.log(typeof checkPivotValue, typeof checkValue);

        if (order === "up") {
          await swapUp(checkValue, checkPivotValue, i);
        } else if (order === "down") {
          await swapDown(checkValue, checkPivotValue, i);
        }
      }

      //ADD END AND START DATES
      if (type === "start_dates" || type === "end_dates") {
        let datesPivot =
          checkPivotValue !== undefined ? sortDates(checkPivotValue) : -1;
        let datesCheck = checkValue !== undefined ? sortDates(checkValue) : -1;

        if (type === "start_dates") {
          checkPivotValue = validateDates(datesPivot);
          checkValue = validateDates(datesCheck);

          if (order === "up") {
            await swapUp(checkValue, checkPivotValue, i);
          } else if (order === "down") {
            await swapDown(checkValue, checkPivotValue, i);
          }
        }

        if (type === "end_dates") {
          checkPivotValue = validateDates(datesPivot);
          checkValue = validateDates(datesCheck);

          if (order === "up") {
            await swapUp(checkValue, checkPivotValue, i);
          } else if (order === "down") {
            await swapDown(checkValue, checkPivotValue, i);
          }
        }
      }
    }
    swap(arr, pivotIndex, end);
    return pivotIndex;
  };

  const swap = async (arr, a, b) => {
    let temp = arr[a];
    arr[a] = arr[b];
    arr[b] = temp;
  };
  let results = "";

  //prevent run on empty search
  if (findingAids.length > 0) {
    results = await quickSort(findingAids, 0, findingAids.length - 1);
  }
  return dispatch({
    type: SORT_FINDINGAIDS,
    payload: [...results],
  });
};