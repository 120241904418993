import { useState, useEffect, useCallback } from "react";

export default function useImgResize(parent, img) {
  const [logo, setLogo] = useState(null);
  const [parentWidth, setParentWidth] = useState(0);
  const [parentHeight, setParentHeight] = useState(0);
  const [logoHeight, setLogoHeight] = useState(null);
  const [logoWidth, setLogoWidth] = useState(null);
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  let style = "";

  const setStyle = (w, h) => {
    if (h > w) {
      if (parentHeight > h) {
        // console.log("parent heigh is larger");
        style = {
          padding: "1%",
          minHeight: `${Math.floor(parentHeight / logoHeight)}%`,
          maxHeight: `${windowHeight / 3}px`,
          width: "auto",
        };
      } else {
        // console.log("parent heigh is smaller");
        style = {
          padding: "1%",
          minHeight: `${parentHeight}px`,
          maxHeight: "35%",
          width: "auto",
        };
      }
    } else if (w > h) {
      if (parentWidth > w) {
        // console.log("parent width is larger");
        style = {
          padding: "1%",
          minWidth: `${Math.floor(parentWidth / logoWidth)}px`,
          maxWidth: "80%",
          height: "auto",
        };
      } else {
        // console.log("parent width is smaller");
        style = {
          marginTop: "2em",
          padding: "1%",
          minWidth: `${parentWidth}px`,
          maxWidth: "90%",
          height: "auto",
        };
      }
    } else if (w !== 0 && h !== 0) {
      // console.log('square')
      style = {
        padding: "1%",
        width: `100%`,
        height: "auto",
      };
    }
    return style;
  };

  const handleImgResize = useCallback(
    (e) => {
      if (parent.current === null) {
        setParentWidth(windowWidth / 2);
        setParentHeight(windowHeight / 4);
        setLogo(img);
      } else {
        //we always have to check to see if the size of the parent element has changed.
        setParentWidth(parent.current.offsetWidth);
        setParentHeight(parent.current.offsetHeight);
      }
    },

    [parentWidth]
  );

  useEffect(() => {
    setLogo(img);
    if (logo !== null && logo !== undefined) {
      //the size of the logo should not change once loaded, will not required additional resets
      setLogoHeight(logo.current.height);
      setLogoWidth(logo.current.width);
    }

    setParentWidth(parent.current.offsetWidth);
    setParentHeight(parent.current.offsetHeight);

    window.addEventListener("resize", handleImgResize);

    // return () => {
    //   window.removeEventListener("resize", handleImgResize);
    // };
  }, [handleImgResize, windowWidth, windowHeight]);
  return setStyle(logoWidth, logoHeight);
}
