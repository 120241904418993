import React from "react";
import { connect } from "react-redux";
import { searchFindingAids } from "../../actions/searchFindingAids";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

function determineSubjectType(str) {
  // console.log(str)
  try {
    let label = str.split(":")[0]
    if (label.includes("persons") || label.includes("Persons")) {
      return "subject_persons"
    } else if (label.includes("organizations") || label.includes("Organizations")) {
      return "subject_organizations"
    } else if (label.includes("places") || label.includes("Places") || label.includes("geographic areas") || label.includes("Geographic Areas")) {
      return "geographic_areas"
    } else {
      return "subject_topics"
    }
  } catch (error) {
    return "subject_topics"
  }
}

function returnModifiedString(str) {
  try {
    if (str.includes("Document Types") || str.includes("document types")) {
      return str
    }
    let urlBase = `${process.env.REACT_APP_API_BASE}`;
    let subjectSearchType = determineSubjectType(str)
    let htmlString = str
    let splitString = htmlString.split("<li>")
    let replacementStrings = []
    for (let i=1; i<splitString.length; i++) {
      let cleanedValue = splitString[i].split("</li>")[0].replace(" ", "")
      let newUrlString = `<li><a href="${urlBase}/search/${subjectSearchType}=${cleanedValue}">${cleanedValue}</a></li>`
      replacementStrings.push(newUrlString)
    }
    let modifiedString = htmlString.split("<li>")[0]
    for (let i=0; i<replacementStrings.length; i++) {
      modifiedString = modifiedString + replacementStrings[i]
    }
    return modifiedString
  } catch (error) {
    return str
  }
}

//formally known as ControlAccess, file name SubjectTerms
function SubjectTerms(props) {
  try {
    // console.log('subject terms',props);
    
    return (
      <AccordionItem uuid="subject-terms" className="summary card ">
        <AccordionItemHeading className="card-header">
          <AccordionItemButton>
            <a name={props.name}>
              <p className="card-title accordion-headers">Subject Terms</p>
            </a>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="card-body">
          <div className="card-text">
            {Object.keys(props.list).map((item, i) => (
              <div key={i}>
                {item.includes("</strong>") ? (
                  <p
                    tabIndex="0"
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }}
                  ></p>
                ) : (
                  <p
                    className="label"
                    tabIndex="0"
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }}
                  ></p>
                )}
                <ul>
                  {Object.entries(props.list[item]).map(([key, value], i) => {
                    {
                      /* console.log('items', key, value) */
                    }
                    return (
                      <li
                        className="text-list"
                        tabIndex="0"
                        dangerouslySetInnerHTML={{
                          __html: returnModifiedString(value),
                        }}
                      ></li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>
        </AccordionItemPanel>
      </AccordionItem>
    );
  } catch (err) {
    return (
      <AccordionItem uuid="subject-terms" className="summary card ">
        <AccordionItemHeading className="card-header">
          <AccordionItemButton>
            <a name={props.name}>
              <p className="card-title accordion-headers">Subject Terms</p>
            </a>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="card-body">
          <div className="card-text">
            {Object.keys(props.list).map((item, i) => (
              <div key={i}>
                <p
                  className="label"
                  tabIndex="0"
                  dangerouslySetInnerHTML={{
                    __html: item,
                  }}
                ></p>
                <p
                  className="text "
                  tabIndex="0"
                  dangerouslySetInnerHTML={{
                    __html: " " + props.list[item],
                  }}
                ></p>
              </div>
            ))}
          </div>
        </AccordionItemPanel>
      </AccordionItem>
    );
  }
}

const mapStateToProps = (state) => ({
  findingAid: state.findingAids.findingAids,
});

export default connect(mapStateToProps, { searchFindingAids })(SubjectTerms);
