import {useEffect} from "react";
import RepositoryDisplay from "./RepositoryDisplay";
import { scrollToTop } from "../Utils/ScrollToTop";

function RepositoryLandingPage(props) {

  useEffect(() => {
    scrollToTop();
  }, [window.history.pathname]);

  return (
    <div>
      <RepositoryDisplay repo={props.match.params.repository} />
    </div>
  );
}

export default RepositoryLandingPage;
