import React, { useRef } from "react";
import XmlIcon from "../../../../images/file-text.svg";

const buildLink = (url) => {
  const split = url.split("/");
  const link = `${process.env["REACT_APP_API_BASE"]}/admin/${split[3]}/${split[5]}`;
  return link;
};

const XmlLink = () => {
  let url = buildLink(window.location.href);
  return (
    <div>
      <button type="button" className="btn btn-link">
        <a href={url} target="_blank" rel="noopener noreferrer">
          Raw XML&nbsp;
          <img src={XmlIcon} className="icon" alt="xml icon" />
        </a>
      </button>
    </div>
  );
};

export default XmlLink;
