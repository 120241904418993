import React, { useState, useEffect } from "react";

function LetterSort(props) {
  const [sortLetter, setSortLetter] = useState("");
  const { from } = props;
  const location = window.location.pathname;

  const letters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  //onload, it should always reset.
  useEffect(() => {
    setSortLetter("");
  }, [from]);

  useEffect(() => {
    return props.setFiltering(sortLetter);
  }, [sortLetter]);

  const handleReset = () => {
    setSortLetter("");
    // todo: what is this for?
    // props.setCurrentPage(0);
    props.setFiltering("");
    props.filtering(false);
  };

  useEffect(() => {
    handleReset();
  }, [location, from]);

  return (
    <div className="card" styles="width: 18rem;">
      <div className="card-body text-center">
        {letters.map((letter, i) => (
          <span
            key={i}
            className="letter-sort__btn"
            aria-label={`letter sort button ${letter}`}
            onClick={() => setSortLetter(letter)}
          >
            {letter === sortLetter ? (
              <span className="selected-letter">{letter}</span>
            ) : (
              letter
            )}
          </span>
        ))}
        <button
          className="btn btn-primary dark-bg ml-3"
          onClick={() => handleReset()}
        >
          Reset
        </button>
      </div>
    </div>
  );
}

export default LetterSort;
