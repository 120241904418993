import React from "react";
import { Link } from "react-router-dom";

function ArchivesPhaseOne() {
  document.title = "Texas Archival Resources Online - Phase 1";
  return (
    <div>
      <center>
        <h1>TARO ARCHIVES</h1>
      </center>
      <p></p>
      <div className="archives_page">
        <ul>
          <li>
            <strong>
              <a href="https://github.com/txarchives/archives/blob/main/phase_1/TARO_-_Phase_1_-_Overview.pdf">
                Overview
              </a>
            </strong>
          </li>
          <li>
            <strong>Search Interface Requirements</strong>
            <ul>
              <li>
                <strong>
                  <a href="https://github.com/txarchives/archives/blob/main/phase_1/search/Phase_1_-_Initial_Search_Interface_Requirements.pdf">
                    Initial Search Interface Requirements
                  </a>
                </strong>
              </li>
              <li>
                <strong>
                  <a href="https://github.com/txarchives/archives/blob/main/phase_1/search/Phase_1_-_Final_Search_Interface_Requirements.pdf">
                    Final Search Interface Requirements
                  </a>
                </strong>
              </li>
            </ul>
          </li>
          <li>
            <strong>
              <a href="https://github.com/txarchives/archives/blob/main/phase_1/TARO_-_Phase_1_-_Hardware_Software.pdf">
                Hardware/Software Specifications
              </a>
            </strong>
          </li>
          <li>
            <strong>Meetings</strong>
            <ul>
              <li>
                <strong>
                  TARO Project Meeting -
                  <a href="https://github.com/txarchives/archives/blob/main/phase_1/meetings/Project_Meeting_%E2%80%93_December_9_1999.pdf">
                    December 9, 1999
                  </a>
                </strong>
              </li>
              <li>
                <strong>
                  TARO Project Meeting -
                  <a href="https://github.com/txarchives/archives/blob/main/phase_1/meetings/Project_Meeting_%E2%80%93_January_20_2000.pdf">
                    January 20, 2000
                  </a>
                </strong>
              </li>
            </ul>
          </li>
          <li>
            <strong>Archived News</strong>
            <ul>
              <li>
                <strong>
                  <a href="https://github.com/txarchives/archives/blob/main/phase_1/news/2002_TARO_-_News.pdf">
                    2002_TARO_-_News
                  </a>
                </strong>
              </li>
              <li>
                <strong>
                  <a href="https://github.com/txarchives/archives/blob/main/phase_1/news/2002-2014_TARO_-_News.pdf">
                    2002-2014_TARO_-_News
                  </a>
                </strong>
              </li>
              <li>
                <strong>
                  <a href="https://github.com/txarchives/archives/blob/main/phase_1/news/2014-2015_TARO_-_News.pdf">
                    2014-2015_TARO_-_News
                  </a>
                </strong>
              </li>
            </ul>
          </li>
          <li>
            <strong>Forms</strong>
            <ul>
              <li>
                <strong>
                  <a href="https://github.com/txarchives/archives/blob/main/phase_1/forms/TIFB_Cost_Sharing_Travel_Expenses_Form.pdf">
                    Travel Expenses Form
                  </a>
                </strong>
              </li>
              <li>
                <strong>
                  <a href="https://github.com/txarchives/archives/blob/main/phase_1/forms/Finding_Aid_Conversion_Cover_Sheet_Form.pdf">
                    Finding Aid Conversion Cover Sheet Form
                  </a>
                </strong>
              </li>
              <li>
                <strong>
                  <a href="https://github.com/txarchives/archives/blob/main/phase_1/forms/checklist.pdf">
                    Apex Checklist
                  </a>
                </strong>
              </li>
            </ul>
          </li>
          <li>
            <strong>
              <Link to={`/archives/stats`}>Archived Statistics</Link>
            </strong>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ArchivesPhaseOne;
