import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Email from "../../images/envelope.svg";
import DownArrow from "../../images/chevron-double-down.svg";
import UpArrow from "../../images/chevron-double-up.svg";
import { searchFindingAids } from "../../actions/searchFindingAids";
import { Link } from "react-router-dom";
import Loading from "../Utils/Loading";
import { wordRestrict } from "../Utils/WordCounter";

function Home(props) {
  const [repository, setRepository] = useState("");
  const [allFindingAids, setAllFindingAids] = useState([]);
  const [displayAids, setDisplayAids] = useState([]);
  const [displayNum, setDisplayNum] = useState(3);
  const { repositoryData, findingAids } = props;
  const taroEmail = "taro-lib@email.com";

  //1. On the initial load Performs query for finding aids based on date_added in desc order .
  useEffect(() => {
    props.searchFindingAids(`sort_dsc=date_added&recent`);
  }, []);
  //After we receive this information on load, it will trigger the page to re-render.
  //2. Check for finding aid query results and populate component state
  //3. Checks to see if repositoryData from redux is present then picks out random repository.

  //both of these items should happen once and will trigger a render.
  useEffect(() => {
    setAllFindingAids(findingAids);
    if (repositoryData.length > 0 && !repository) {
      const randRepoIndex = Math.floor(Math.random() * repositoryData.length);
      const randRepo = repositoryData[randRepoIndex];
      setRepository(randRepo);
    }
  }, [repositoryData, findingAids]);

  //4. Collects finding aids to present based on displayNum - should only trigger if view more is clicked.
  useEffect(() => {
    if (allFindingAids.length > displayNum) {
      setDisplayAids(allFindingAids.slice(0, displayNum));
    } else {
      setDisplayAids(allFindingAids);
    }
  }, [allFindingAids, displayNum]);

  const currentPageData = displayAids.map((result, i) => (
    <div className="repository-link card" key={i}>
      <div className="card-body">
        <div className="finding-aid card" key={i}>
          <div className="card-body">
            <p className="card-title d-inline-block homepage-header">
              {/* Title: */}{" "}
              <Link
                to={`/${result.repository}/finding_aids/${result.filename}`}
                className="card-text"
              >
                {result.title}
              </Link>
            </p>
            <p className="card-subtitle mb-2 text-muted results-text">
              {/* Abstract:  */}
              {wordRestrict(result.abstract)}
            </p>
            {result.repository_name && (
              <p className="card-text results-text">
                {/* Repository: */}
                <Link to={`/repositories/${result.repository}`}>
                  {result.repository_name}
                </Link>{" "}
              </p>
            )}
            {/* the short name, do we want this to be a link to the repo?  */}
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="blue-grey">
      <br />
      <center>
        <h1>Welcome!</h1>
      </center>
      <div>
        <p className="container mt-4 lead text-center">
          TARO (Texas Archival Resources Online) makes descriptions of the rich
          archival, manuscript, and museum collections in repositories across
          the state available to the public. The site consists of the collection
          descriptions or "finding aids" that archives, libraries, and museums
          create to assist users in locating information in their collections.
          Consider these an extended table of contents which describe unique
          materials only available at the individual repositories. In most
          cases, the collections themselves are NOT available online.
        </p>
        <div className="text-center card-body">
          <p className="lead card-text">
            <a
              aria-label={`email to ${taroEmail}`}
              href={`mailto:${taroEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            ></a>
          </p>
        </div>
      </div>
      <div className="container mt-4">
        <center>
          <h1>Repository Spotlight</h1>
        </center>
        <div className="mt-4 repository-highlight">
          <center>
            <Link to={`/repositories/${repository.abbr_name}`}>
              <p className="homepage-header">{repository.name}</p>
            </Link>
          </center>
          <div className="container text-center">{repository.description}</div>
        </div>
      </div>
      <div className="container mt-4 text-center pb-4">
        <h1 id="finding-aid-results">Finding Aids Recently Added</h1>
        <div className="container text-left">
          {displayAids.length > 0 ? (
            currentPageData
          ) : (
            <Loading results={displayAids} from="home" />
          )}
        </div>
        {displayNum !== 15 && (
          <div className="home-change-view text-center d-inline-block mt-3">
            <div
              className="lead"
              aria-label="results expand"
              onClick={() => {
                setDisplayNum(15);
              }}
            >
              <div className="lead">View more</div>
              <img src={DownArrow} className="icon" alt="expand down icon" />
            </div>
          </div>
        )}
        {displayNum !== 3 && (
          <div className="home-change-view text-center d-inline-block mt-3">
            <a
              className="lead"
              href="#finding-aid-results"
              aria-label="results cascade"
              onClick={() => {
                setDisplayNum(3);
              }}
            >
              <div className="lead">View less</div>
              <img src={UpArrow} className="icon" alt="cascade up icon" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  repositoryData: state.repository.repositoryData,
  findingAids: state.findingAids.findingAids,
});

export default connect(mapStateToProps, { searchFindingAids })(Home);
