import React from "react";
import { connect } from "react-redux";
import { searchFindingAids } from "../../actions/searchFindingAids";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

function CollectionSummary(props) {
  // console.log('PROPS COLLECTION SUMMARY ', props)
  return (
    <AccordionItem
      uuid="collection-summary"
      className="summary card collection-summary"
    >
      <AccordionItemHeading className="card-header">
        <AccordionItemButton>
          <a name={props.name}>
            <p className="card-title accordion-headers" id="collection_summary">
              Collection Summary
            </p>
          </a>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="card-body">
        <div className="card-text">
          {Object.keys(props.list).map((item, i) => {
            let class_name = item.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
            {
              {
                {
                  /* console.log("item", class_name); */
                }
              }
            }
            if (item !== "Title_Proper:") {
              return (
                <div key={i} className="block-text">
                  {item.includes("</strong>") ? (
                    <p
                      tabIndex="0"
                      dangerouslySetInnerHTML={{
                        __html: item,
                      }}
                    ></p>
                  ) : (
                    <p
                      className="label"
                      tabIndex="0"
                      dangerouslySetInnerHTML={{
                        __html: item,
                      }}
                    ></p>
                  )}
                  {typeof props.list[item] === "object" &&
                  props.list[item].length > 1 ? (
                    <ul key={i} className={`collection-list ${class_name}`}>
                      {props.list[item].map((entry, i) => (
                        <li
                          key={i}
                          className="collection-list-item"
                          style={{ marginLeft: "1.5em" }}
                          tabIndex="0"
                          dangerouslySetInnerHTML={{
                            __html: entry,
                          }}
                        ></li>
                      ))}
                    </ul>
                  ) : class_name === "languages" ||
                    class_name === "langmaterial" ? (
                    <p
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html:
                          `<span className=${class_name}>` +
                          props.list[item] +
                          "</span>",
                      }}
                    ></p>
                  ) : (
                    <p
                      className={`text ${class_name}`}
                      dangerouslySetInnerHTML={{
                        __html: " " + props.list[item],
                      }}
                    ></p>
                  )}
                </div>
              );
            }
          })}
        </div>
      </AccordionItemPanel>
    </AccordionItem>
  );
}

const mapStateToProps = (state) => ({
  findingAid: state.findingAids.findingAids,
});

export default connect(mapStateToProps, { searchFindingAids })(
  CollectionSummary
);
