import React from "react";
import Home from "../../images/house.svg";
import Book from "../../images/book.svg";
import Email from "../../images/envelope.svg";
import Map from "../../images/geo-alt.svg";

const googleMapBase = "https://www.google.com/maps/search/?api=1";

function RepoLinks({ links, address }) {
  return (
    <div className="card-body">
      {links.home !== undefined && (
        <p className="lead card-text">
          <a href={links.home} target="_blank" rel="noopener noreferrer">
            Repository Home&nbsp;
            <img src={Home} className="icon" alt="home icon" />
          </a>
        </p>
      )}
      {links.access !== undefined && (
        <p className="lead card-text">
          <a href={links.access} target="_blank" rel="noopener noreferrer">
            Access Materials&nbsp;
            <img src={Book} className="icon" alt="materials (book) icon" />
          </a>
        </p>
      )}
      {links.contact !== undefined && (
        <p className="lead card-text">
          <a
            href={`mailto:${links.contact}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact This Repository&nbsp;
            <img src={Email} className="icon" alt="email icon" />
          </a>
        </p>
      )}
      {address !== null && (
        <p className="lead card-text">
          <a
            href={encodeURI(`${googleMapBase}&query=${address}`)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Repository Location&nbsp;
            <img src={Map} className="icon" alt="map (geo-alt) icon" />
          </a>
        </p>
      )}
    </div>
  );
}

export default RepoLinks;
