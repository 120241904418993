import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/taro_logo.png";

function TaroLogo() {
  const parentRef = useRef(null);
  const imgRef = useRef(null);

  return (
    <div id="taro-logo-container">
      <div ref={parentRef}>
        <Link to={`/home`}>
          <img
            src={Logo}
            ref={imgRef}
            id="taro-logo"
            alt="texas archival resources online logo"
          />
        </Link>
      </div>
    </div>
  );
}
export default TaroLogo;
