import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function Loading(props) {
  //props -> where the item is coming from
  //if after period of time provide directions.
  const { from, results } = props;

  const timeOut = () => {
    switch ((from, results)) {
      case from === "repository":
        return (
          <div>
            <h3 className="text-center">Search Repository...</h3>
            <br />
          </div>
        );
      case from === "creators":
        return (
          <div>
            <h3 className="text-center">Search Creators List...</h3>
            <br />
          </div>
        );
      default:
        //add a second animation?
        return <h3>Fetching data...</h3>;
    }
  };

  return (
    <div className="card">
      <div className="loader-overlay">
        <center>
          <div id="loader">
            <Loader
              type="Bars"
              color="#4B6685"
              height={100}
              width={100}
              timeout={6000}
            />
          </div>
          {/* {timeOut()} */}
        </center>
      </div>
    </div>
  );
}

export default Loading;
