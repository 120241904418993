import React from "react";
import FooterDisplay from "./FooterDisplay";

function Footer() {
  return (
    <div>
      <FooterDisplay />
    </div>
  );
}

export default Footer;
