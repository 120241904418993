import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { searchFindingAid } from "../../actions/searchFindingAid";
import { searchRepositories } from "../../actions/searchRepositories";
import CollectionSummary from "./CollectionSummary";
import TitleAndLogo from "./TitleAndLogo";
import CollectionDetails from "./CollectionDetails";
import CollectionRestrictions from "./CollectionRestrictions";
import AdministrativeInfo from "./AdministrativeInfo";
import OtherResources from "./OtherResources";
import SubjectTerms from "./SubjectTerms";
import OtherInformation from "./OtherInformation";
import Inventory from "./Inventory";
import Toggle from "../../images/list.svg";
import { Accordion } from "react-accessible-accordion";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// Parent component for the display.
function FindingAidPrintContent(props) {
  const location = useLocation();
  const toggled = props.toggled;
  const [summary, setSummary] = useState([]);
  const [details, setDetails] = useState([]);
  const [restrictions, setRestrictions] = useState([]);
  const [adminInfo, setAdminInfo] = useState([]);
  const [otherResources, setOtherResources] = useState([]);
  const [collectionTitle, setCollectionTitle] = useState([]);
  const [subjectTerm, setSubjectTerm] = useState([]);
  const [otherInfo, setOtherInfo] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [logo, setLogo] = useState([]);
  const [repoName, setRepoName] = useState([]);
  let passed = [];

  let results =
    Object.keys(props.findingAid).length > 0
      ? JSON.parse(props.findingAid[0].value)
      : null;

  // console.log("print log ? ", results);
  let repo_results =
    Object.keys(props.repositoryData).length > 0 ? props.repositoryData : null;

  useEffect(() => {
    if (Object.keys(props.findingAid).length > 0) {
      const collectionSummaryData = results["collection summary"];
      const collectionTitle = collectionSummaryData["Title_Proper:"]; // todo: change how this happens, doesn't work
      const collectionDetailsData = results["collection details"];
      const collectionRestrictionsData = results["collection restrictions"];
      const adminInfoData = results["administrative information"];
      const otherResourcesData = results["other resources"];
      const subjectTermData = results["controlled access_terms"]; //updated content to subject terms per end user request. front end changes made to reflect text updated.
      const otherInformationData = results["other information"];
      const inventorySeries = results["inventory series"];

      // console.log("PRINT === inventory series information ", inventorySeries);

      setSummary(collectionSummaryData);
      setDetails(collectionDetailsData);
      setRestrictions(collectionRestrictionsData);
      setAdminInfo(adminInfoData);
      setOtherResources(otherResourcesData);
      if (collectionTitle) {
        setCollectionTitle(
          collectionTitle.length > 1
            ? collectionTitle.join("")
            : collectionTitle[0]
        );
      }
      setSubjectTerm(subjectTermData);
      setOtherInfo(otherInformationData);
      setInventory(inventorySeries);
    }

    // console.log("title :", collectionTitle);
    // console.log("PRINT INVENTORY DETAILS }} ", inventory);

    // console.log('summary', summary)
    if (Object.keys(props.repositoryData).length > 0) {
      let currentRepo = window.location.pathname.split("/")[1];
      for (const repo of repo_results) {
        if (currentRepo === repo.abbr_name) {
          setLogo(`/admin/${repo.logo}`);
          setRepoName(repo.name);
        }
      }
    }
  }, [props.findingAid, props.repositoryData]);

  // todo - this could be dryer. all this is repeated in FindingAidDisplay bc it's needed
  // for the Table of Contents
  const components = [
    {
      name: "Collection Summary",
      component: (
        <CollectionSummary list={summary} name="#collection_summary" />
      ),
      data: summary,
      anchor: "#collection_summary",
    },
    {
      name: "Collection Details",
      component: (
        <CollectionDetails list={details} name="#collection_details" />
      ),
      data: details,
      anchor: "#collection_details",
    },
    {
      name: "Collection Restrictions",
      component: (
        <CollectionRestrictions
          list={restrictions}
          name="#collection_restrictions"
        />
      ),
      data: restrictions,
      anchor: "#collection_restrictions",
    },
    {
      name: "Administrative Information",
      component: <AdministrativeInfo list={adminInfo} name="#admin_info" />,
      data: adminInfo,
      anchor: "#admin_info",
    },
    {
      name: "Other Resources",
      component: (
        <OtherResources list={otherResources} name="#other_resources" />
      ),
      data: otherResources,
      anchor: "#other_resources",
    },
    {
      //formally known as control access
      name: "Subject Terms",
      component: <SubjectTerms list={subjectTerm} name="#subject_terms" />,
      data: subjectTerm,
      anchor: "#subject_terms",
    },
    {
      name: "Other Information",
      component: <OtherInformation list={otherInfo} name="#other_info" />,
      data: otherInfo,
      anchor: "#other_info",
    },
    {
      name: "Inventory",
      component: <Inventory list={inventory} name="#inventory" />,
      data: inventory,
      anchor: "#inventory",
    },
  ];

  const validate = (list) => {
    for (let i = 0; i < list.length; i++) {
      let { data } = list[i];
      if (typeof data === "object" && data.length !== 0) {
        passed.push(list[i]);
      }
    }
    // console.log('valid list', list)
  };

  validate(components);

  //make sure we render on changes to the component information and make sure everything is still valid
  useEffect(() => {
    validate(components);
  }, [
    summary,
    details,
    restrictions,
    adminInfo,
    otherResources,
    subjectTerm,
    otherInfo,
    inventory,
  ]);

  return (
    <div className="col" id="finding-aid-all-content">
      <div className="sticky-top sidebar-toggle-wrapper d-lg-none">
        <button
          className={`btn sidebar-toggle dark-bg sidebar-toggle-body not-toggled ${
            toggled ? "hidden" : "show"
          }`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebar"
          aria-expanded="false"
          aria-controls="collapseSidebar"
          onClick={() => {
            props.setToggled(!toggled);
          }}
        />
      </div>
      <div className="summary card">
        <div className="card-body">
          <center>
            <TitleAndLogo
              currentRepo={location.pathname.split("/")[1]}
              collection={collectionTitle}
              logo={logo}
              title={repoName}
              description={
                summary["Abstract:"]
                  ? summary["Abstract:"][0].trim()
                  : collectionTitle
              }
            />
          </center>
          <Accordion
            allowMultipleExpanded={true}
            allowZeroExpanded={true}
            preExpanded={[
              "collection-summary",
              "administrative-info",
              "collection-details",
              "collection-restrictions",
              "subject-terms",
              "other-information",
              "other-resources",
              "inventory",
            ]}
          >
            {passed.length > 0
              ? passed.map((item, id) => {
                  let output = [];
                  if (item.name === "Inventory") {
                    {
                      /* console.log("details? ", item); */
                    }
                    item.component.props.list.forEach((subitem, index) => {
                      let current_key = Object.keys(subitem)[0];
                      {
                        /* console.log(
                        "subitem",
                        current_key,
                        "\n ",
                        subitem[current_key]
                      ); */
                      }
                      if (current_key === "c01") {
                        try {
                          subitem[current_key].container_summary[
                            ""
                          ] = `<a style='position:relative; top:-100px'; name='#series${index}'></a>`;
                        } catch (error) {
                          console.log(`error: ${error}`);
                        }
                      }
                    });
                  }
                  output = <div key={id}>{item.component}</div>;
                  {
                    /* console.log('output', output) */
                  }
                  return output;
                })
              : null}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  findingAid: state.findingAid.findingAid,
  repositoryData: state.repository.repositoryData,
});

export default connect(mapStateToProps, {
  searchFindingAid,
  searchRepositories,
})(FindingAidPrintContent);
