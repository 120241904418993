import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import useImgResize from "../../Hook/useImgResize";

export default function TitleAndLogo({
  logo,
  currentRepo,
  title,
  collection,
  description,
}) {
  const parentRef = useRef(null);
  const imgRef = useRef(null);
  const logoURL = `${process.env.REACT_APP_API_BASE}${logo}`;
  let styling = useImgResize(parentRef, imgRef);

  const temp = `${collection}`;
  const pageTitle = temp.replace(/<([^\d\s])([^>]+){0,1}>/gi, "");
  let docTitle = `${pageTitle} - TARO`.trim();
  let pageUrl = window.location.href;

  document.title = docTitle;

  return (
    <div className="findingaid-header">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{docTitle}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <div ref={parentRef}>
        {logo && (
          <Link to={`/repositories/${currentRepo}`}>
            <img
              ref={imgRef}
              src={`${logoURL}`}
              alt={`logo for ${title}`}
              style={styling}
            />
          </Link>
        )}
      </div>
      <h1
        className="card-title findingaid-title"
        tabIndex="0"
        dangerouslySetInnerHTML={{
          __html: collection,
        }}
      ></h1>
    </div>
  );
}
