import React from "react";

function RepositoriesHeader(props) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-xs-12 card justify-content-center">
            <div className="card-body align-items-center">
              <p className="card-title m-auto text-center results-header">
                View Our {props.total} Repositories
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RepositoriesHeader;
