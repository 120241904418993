import React, { useState } from "react";
import { connect } from "react-redux";
import { searchFindingAids } from "../../../../actions/searchFindingAids";
import CitationWindow from "./CitationWindow";

function CitationButton(props) {
  const [showCite, setShowCite] = useState(false);
  const details = props.cite.citeInfo; //since it doesn't appear this information is always passed, than should we change how that information is displayed?

  const handleShow = () => {
    setShowCite(!showCite);
  };

  return (
    <div className="citations">
      <center>
        <button type="submit" className="btn" onClick={handleShow}>
          Citations
        </button>
        <CitationWindow
          info={details === undefined ? "ops" : details}
          open={showCite}
          close={setShowCite}
        />
      </center>
    </div>
  );
}

const mapStateToProps = (state) => ({
  findingAid: state.findingAids.findingAids,
});

export default connect(mapStateToProps, { searchFindingAids })(CitationButton);
