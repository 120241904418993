import React, { useRef } from "react";
import useImgResize from "../../Hook/useImgResize";
import SearchForm from "./RepoSearch";
import RepoLinks from "./RepoLinks";
import Archive from "../../images/archive.svg";

function RepoJumbo({
  repoTitle,
  repoLogo,
  repoDescription,
  repoAddress,
  repoAbbr,
  links,
}) {
  const parentRef = useRef(null);
  const imgRef = useRef(null);
  const styling = useImgResize(parentRef, imgRef);

  return (
    <div className="jumbotron repo-jumbo">
      <div className="container">
        <center>
          <div ref={parentRef}>
            <img
              ref={imgRef}
              src={repoLogo}
              alt={`logo for ${repoTitle}`}
              style={styling}
            />
          </div>
        </center>
      </div>
      <h2 className="display-4 text-center">{repoTitle}</h2>
      <p className="lead text-center">{repoDescription}</p>
      <p className="text-center lead">
        <a href={links.about} target="_blank" rel="noopener noreferrer">
          Discover more about this repository&nbsp;
          <img src={Archive} className="icon" alt="archive icon" />
        </a>
      </p>
      <hr className="my-4"></hr>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-xs-12 text-center">
            <RepoLinks links={links} address={repoAddress} />
          </div>
          <div className="col-lg-6 col-xs-12 text-center">
            <SearchForm repo={repoAbbr} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RepoJumbo;
