import React, { useEffect, useState } from "react";
import LetterSort from "./letterSort";

function ResultsHeader(props) {
  const {
    display,
    from,
    filtering,
    setFiltering,
    total,
    term,
    setCurrentPage,
  } = props;
  const [offset, setOffset] = useState(1);

  // console.log("header", props);
  //functions just for creators
  useEffect(() => {
    setOffset(1);
  }, [props.perPage, term, display, from, total]);

  useEffect(() => {
    setOffset(Math.floor(props.pageNumber * props.perPage + 1));
  }, [props.pageNumber]);

  return (
    <div className="card">
      <div className="card-body">
        <div className="row justify-content-center">
          {from === "creators" ? (
            <div className="col-lg-2 col-xs-12 card">
              <div className="card-body d-flex flex-column justify-content-center align-items-center py-3 m-auto">
                <div className="input-group ">
                  <label htmlFor="inputGroupSelect01">Display Results</label>
                </div>
                <select
                  className="custom-select page-size__select"
                  id="inputGroupSelect01"
                  onChange={(e) => {
                    props.setLoading(true);
                    props.setPerPage(Number(e.target.value));
                    props.setLoading(false);
                  }}
                >
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          ) : null}

          <div className="col-lg-8 col-xs-12 card">
            <div
              className={`d-flex result-summary ${
                props.terms ? "with" : "without"
              }`}
            >
              <div className="card-body d-flex align-items-center">
                {from === "creators" ? (
                  <h5 className="card-title m-auto text-center">
                    Results Summary ( {offset} -{" "}
                    {display ? (display > total ? total : display) : 0} of{" "}
                    {total} Total )
                  </h5>
                ) : (
                  <h5 className="card-title m-auto text-center">
                    Results Summary {display > 0 ? 1 : 0} - {display} of {total}{" "}
                    Total
                  </h5>
                )}
              </div>
            </div>

            <LetterSort
              setFiltering={setFiltering}
              filtering={filtering}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultsHeader;
