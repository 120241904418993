import axios from "axios";
import { SEARCH_ALLOWEDLIST } from "./types";
import { tokenConfig } from "./auth";

// SEARCH FINDING AIDS
// Note: this is used for browse, filter, and sort as well.
// Only the params in the query change.
export const searchAllowedList = (query) => async (dispatch, getState) => {
  await axios
    .get(
      `${process.env.REACT_APP_API_BASE}/api/allowlists/search/?${query}`,
      tokenConfig(getState)
    )
    .then((response) => {
      dispatch({
        type: SEARCH_ALLOWEDLIST,
        payload: response.data,
      });
    })
    .catch((err) => {
      if (err.response.status === 500) {
        window.location.href = "/system_down";
      } else {
        console.log(err)
      }
    });
};
