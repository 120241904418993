import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { searchFindingAids } from "../../actions/searchFindingAids";
import { useInputText } from "../../Hook/useInputText";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

//Styling for screen reader only
const srOnly = {
  position: "absolute",
  left: -10000 + "px",
  top: "auto",
  width: 1 + "px",
  height: 1 + "px",
  overflow: "hidden",
};

//TODO: should remove blank values, does not need to be passed in

function SearchForm(props) {
  const [repository, setRepository] = useState([""]);
  const [results, setResults] = useState({});
  const [digital, setDigital] = useState(false);
  const [error, setError] = useState(false);

  const digitalToolTip =
    "Search finding aids with links to digitized collection material.";

  const errorMsg = [
    { odd: `* Please close your " before you can submit your search` },
    { location: ` * Quotations must include the complete text in this field` },
  ];

  let {
    value: query,
    bind: bindQuery,
    valid: validateQuery,
    errorReason: queryError,
    reset: resetQuery,
  } = useInputText("");

  useEffect(() => {
    if (!validateQuery) {
      setError(true);
    } else {
      setError(false);
    }
  }, [validateQuery]);

  useEffect(() => {
    let newResults = {};
    if (Object.keys(props.repositoryData).length > 0) {
      props.repositoryData.map((entry) => {
        return (newResults[entry.name.trim()] = entry.abbr_name);
      });
    }
    setResults(newResults);
    setDigital(false);
  }, [props.repositoryData]);

  const abbr = results[repository] ? results[repository].trim() : "";

  const QueryDetails = {
    repository: abbr,
    text: /\S/.test(query) ? query.trim() : "",
    digital: digital ? digital == true : "",
  };

  const findErrorMsg = () => {
    let msg = "";
    errorMsg.forEach((entry) =>
      Object.keys(entry).find((key) => {
        if (key === queryError) {
          msg = entry[key];
        }
      })
    );
    return msg;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!error) {
      let queryString = "";

      for (var index in QueryDetails) {
        if (queryString.length > 0 && QueryDetails[index] !== "") {
          queryString += "&";
        }
        if (QueryDetails[index] !== "") {
          queryString += index + "=" + QueryDetails[index];
        }
      }

      if (queryString === "") {
        props.history.push("/search/:query");
      } else {
        props.searchFindingAids(queryString);
        props.history.push(`/search/${queryString}`);
      }
      resetQuery();
      setRepository([""]);
      setDigital(false);
    }
  };

  return (
    <div className="card card-body mt-4 mb-4 blue-grey dark-border">
      <form onSubmit={onSubmit} autoFocus className="simple-search-form">
        <div className="row g-3 align-items-center mb-2">
          <div className="col text-center">
            <label
              htmlFor="simple-search-input"
              className="col-form-label simple-seach-label"
              aria-labelledby="simple-search-input"
            >
              Search Finding Aids
            </label>
          </div>
          <div className="col-sm-9 simple-search-box">
            {!validateQuery ? (
              <p className="quoteError"> {findErrorMsg()} </p>
            ) : null}
            <input
              {...bindQuery}
              id="simple-search-input"
              className="form-control"
              type="text"
              name="query"
              placeholder="Search..."
              value={query}
            />
          </div>
        </div>
        <div className="row g-3 align-items-center mb-2">
          <div className="col text-center">
            <label className="col-form-label simple-seach-label">
              Limit By Repository
            </label>
          </div>
          <div
            className="col-sm-9 simple-search-box"
            aria-label="basic-typeahead-single"
          >
            {Object.keys(results).length > 0 && (
              <Typeahead
                id="basic-typeahead-single"
                labelkey="name"
                label="Limit By Repository"
                onChange={setRepository}
                options={Object.keys(results)}
                placeholder="Limit by repository..."
                aria-required="true"
                aria-label="Limit by repository"
                selected={repository}
                name={repository}
                role="listbox"
                value={repository}
                aria-describedby={repository}
                aria-live="polite"
                aria-labelledby={repository}
                inputProps={{
                  className: "input-group",
                  "aria-haspopup": "true",
                  "aria-live": "polite",
                  "aria-atomic": "true",
                  labelkey: "name",
                }}
                shouldSelect={true}
              />
            )}
          </div>
        </div>
        <div className="form-group mb-0">
          <div className="row align-items-center limit-to-digital">
            <div className="col checkbox-col mb-2">
              <div className="form-check digital-checkbox mb-3 float-left">
                <label
                  htmlFor="simple-search-digital-object"
                  className="form-check-label tooltips"
                  aria-label={`simple-search-digital-object ${digitalToolTip}`}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={digitalToolTip}
                  role="tooltip"
                  delay={{ show: 500, hide: 100 }}
                  tabIndex="0"
                >
                  Limit Results to Digital Objects
                </label>
                <input
                  id="simple-search-digital-object"
                  name="simple-search-digital-object"
                  type="checkbox"
                  aria-label={digital ? "check" : "unchecked"}
                  className="form-check-input simple-search-digital-object"
                  checked={digital}
                  onClick={() => setDigital(!digital)}
                  onChange={() => setDigital(!digital)}
                  style={{ marginLeft: "2em" }}
                />
              </div>
            </div>
            <div className="col-sm-2 simple-search-submit">
              <button
                type="submit"
                className="btn btn-primary dark-bg float-right"
                aria-label="submit-search"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  repositoryData: state.repository.repositoryData,
});

export default withRouter(
  connect(mapStateToProps, { searchFindingAids })(SearchForm)
);
