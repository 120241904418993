import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { searchFindingAids } from "../../actions/searchFindingAids";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Search from "../../images/search.svg";

function SearchForm(props) {
  const [query, setQuery] = useState("");

  const onChange = (e) => {
    setQuery(e.target.value);
  };

  const simpleSearch = "text=";
  const repoLimit = props.repo !== undefined ? `repository=${props.repo}` : "";

  const onSubmit = (e) => {
    e.preventDefault();
    let queryString = "";

    if (/\S/.test(query)) {
      queryString = simpleSearch + query.trim();
    } else {
      setQuery("");
    }

    if (repoLimit.length > 0 && queryString.length > 0) {
      queryString += "&" + repoLimit;
    } else {
      queryString = repoLimit;
    }
    props.searchFindingAids(queryString);
    props.history.push(`/search/${queryString}`);
    setQuery("");
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="repo-search">
          Search finding aids in this repository&nbsp;
          <img src={Search} className="icon" alt="search icon" />
        </label>
        <input
          id="repo-search"
          className="form-control"
          type="text"
          name="query"
          onChange={onChange}
          placeholder="Search..."
          value={query}
        />
      </div>
      <button type="submit" className="btn btn-primary dark-bg">
        Search
      </button>
    </form>
  );
}

export default withRouter(connect(null, { searchFindingAids })(SearchForm));
